.brands_item {
  position: relative;
  margin: 1rem 0 3.2rem 0;
  border: 1px solid #cbd0d3;
  padding: 2rem 2.2rem 2.4rem 2.4rem;
  @include mq(md) {
    padding: 0;
    border: none;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 2.4rem;
  }
  &_flex {
    @include mq(md-min) {
      display: flex;
    }
  }
  .bell_epoc {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    background-color: #8dc220;
    color: #fff;
  }
  .reraku{
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    background-color: #97c736;
    color: #fff;
  }
  .spa{
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    background-color: #ff782a;
    color: #fff;
  }
  .ruam {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    background-color: #d3bd73;
    color: #fff;
  }
  .detail_brands_btn {
    margin-top: 20px;
    font-size: 1.5rem;
    bottom: 10px;
    //コンテンツが完成するまで
    // width: 75%;
  }
  .brands_top_contents_flex {
    @include mq(lg-min) {
      display: flex;
    }
  }
  .contents_centering {
    flex: 1;
    padding: 1rem 1rem 1rem 1rem;
    .heading-block {
      text-align: center;
    }
    .pad {
      margin: 3rem;
    }
    .header_logo_centering {
      width: 40%;
      margin: 0 auto;
      padding: 5rem;
      @include mq(lg){
        width: 100%;
      }
      &_brands {
        height: 150px;
        margin: 0 auto;
        padding: 5rem;
        @include mq(lg){
          width: 100%;
        }
      }
    }
    .contents_menu {
      text-align: center;
      font-size: 1.3rem;
      letter-spacing: 1px;
      line-height: 170%;
      font-weight: bold;
      color: #555555;
      margin: 1rem auto 1rem auto;
    }
    .contents_description {
      font-family: $font-notoJp;
      font-size: 16px;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 170%;
      text-align: center;
      @include mq(md-min){
        padding-top: 3rem;
      }
    }
    .contents_decoration {
      @extend .contents_menu;
      border-top: 3px dotted #555555;
      border-bottom: 3px dotted #555555;
      width: 100%;
      margin: 2rem auto 3rem auto;
      @include mq(md) {
        width: 90%;
      }
      .content_size {
        font-size: 2rem;
        font-family:"ヒラギノ丸ゴ Pro W4",
                    "ヒラギノ丸ゴ Pro",
                    "Hiragino Maru Gothic Pro",
                    "ヒラギノ角ゴ Pro W3",
                    "Hiragino Kaku Gothic Pro",
                    "HG丸ｺﾞｼｯｸM-PRO",
                    "HGMaruGothicMPRO";
      }
      &_sentence {
        @extend .contents_menu;
        font-size: 1.2rem;
        @include mq(lg){
          font-size: 1.2rem;
          width: 90%;
        }
        text-align: left;
        p {
          .line {
            border-bottom: solid #ff8200 2px;
          }
        }
      }
    }
  }
  .brand_contents_centering_img {
    text-align: center;
    margin: 1rem auto;
    width: 90%;
    @include mq(md-min) {
      flex: 1;
    }
    &_heading {
      @extend .brand_contents_centering_img;
      width: 80%;
      @include mq(lg){
        width: 70%;
      }
    }
    &_logo{
      @extend .brand_contents_centering_img;
      margin: 30px auto;
      text-align: center;
      img {
        width: 45%;
        @include mq(md) {
          width: 75%;
        }
      }
    }
  }
}

.heading-block {
  text-align: center;
}
.pad {
  margin: 2rem;
}

.first {
  @include mq(md-min){
    margin-top: 18rem;
  }
}

.half_item {
  @include mq(lg-min){
    display: flex;
  }
}

.flexible_wrap {
  .suggest_contents {
    @include mq(md-min) {
      flex: 1;
      padding: 1rem;
    }
  }
  .suggest_last_contents {
    margin-bottom: 3.2rem;
  }
}

.reraku_color_under_line {
  border-bottom: 4px solid #33AC3A;
  padding-bottom: 7px;
}

.search_result {
  strong {
    span {
      color: #ff8200;
    }
  }
}
