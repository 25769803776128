.breadcrumb {
  @include font-size(15);
  color: #a6adb4;
  margin-bottom: rem(30);
  padding-top: rem(30);
  .inner {
    padding: rem(10) rem(15);
  }
  @include mq {
    padding-top: rem(6);
    margin-bottom: 0;
    background-color: #ebeded;
    @include font-size(7);
    line-height: 1.4;
    .inner {
      padding: rem(6) rem(10);
    }
  }
  a {
    font-weight: bold;
    color: #a6adb4;
    margin-right: rem(3);
  }
}

.suspended-application-job-poting-warning {
  background-color: rgb(255, 84, 84);
  color: rgb(255, 255, 255);
  text-align: center;
  padding: rem(20);
  border-radius: 3px;
  border: #aaaaaa;
  font-weight: bold;
  margin: 10px 0;
  box-shadow: rem(2) rem(2) rem(2) rem(2)#cccccc;
}
.item-flex {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  @include mq(md) {
    display: block;
  }
  &_box_item {
    margin: 1rem;
  }
  &_item_item {
    width: 33%;
    @include mq(md) {
      width: 100%;
      margin-bottom: 3rem;
    }
  }
}

.sec-mainvisual {
  margin-bottom: rem(35);
  @include mq {
    margin-bottom: rem(26);
    padding-top: rem(15);
    a {
      display: block;
      overflow: hidden;
      border-radius: 4px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
  }
}

.sec-recruit {
  padding-bottom: rem(80);
  @include mq(xl) {
    padding-bottom: rem(100);
  }
  @include mq(lg) {
    padding-bottom: rem(50);
  }
  @include mq {
    padding: rem(16) 0;
  }
}
.search {
  &_wrap {
    margin-bottom: rem(32);
    @include mq {
      margin-bottom: rem(23);
    }
  }
  &_contents {
    background: #ffebcc;
    @include mq(md-min){
    }
  }
  &_inner {
    border-radius: 5px;
    padding: rem(24);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include mq {
      padding: rem(16) rem(9) rem(12);
    }

    .btn {
      width: 13.7%;
      background: #fc9c04;
      color: #fff;
      cursor: pointer;
      margin-left: 0.8%;
      text-align: center;
      @include font-size(15);
      @include mq {
        width: 100%;
        margin: 0;
      }
      &:hover {
        opacity: 0.9;
        transition: 0.3s;
      }
    }
  }
  &_values {
    @include mq(md-min) {
      flex-basis:70%;
    }
  }
  &_submit {
    @include mq(md-min) {
      flex-basis: 42%;
      padding: 0.5% 4% 0 0;
    }
  }
  &_select {
    width: 24.9%;
    margin-right: 0.1%;
    @include mq {
      width: 100%;
      margin: 0 0 rem(1);
    }
  }
  &_checkbox {
    padding: 2.5% 8%;
    @include mq(md-min) {
      padding: 0 0 2.5% 5%;
    }
    &_inline {
      @include mq(md-min) {
        display: flex;
      }
      input[type="checkbox"] {
        display: none;
        }
      input[type="checkbox"]:checked + label {
        background: #FC9C04;
        color: #ffffff;
        }
    }
    &_notion {
      font-weight: bold;
      padding-left: 3%;
      font-size: 1.25rem;
    }
    &_collection {
      font-size: 1rem;
      font-weight: bolder;
      flex-basis: 58%;
    }
    &_style {
      vertical-align: text-top;
    }
    &_label {
      padding-right: 5%;
      display: block;
      float: left;
      margin: 1%;
      width: 100px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      padding: 0 2px 0 2px;
      cursor: pointer;
      color: #fc9c04;
      border: 1px solid #fc9c04;
      border-radius: 6px;
      @include mq(md) {
        width: 31%;
        font-size: 1rem;
        white-space: nowrap;
      }
      @include mq(xs) {
        width: 80px;
        font-size: 0.8rem;
        white-space: nowrap;
      }
    }
  }
  &_inp {
    width: 49.9%;
    @include mq {
      width: 100%;
      margin: 0 0 rem(6);
    }
    input {
      border: 1px solid #ebeded;
    }
    input::placeholder {
      color: #b0b0b0;
    }

  }
  &_result {
    @include font-size(13);
    margin-bottom: rem(15);
    strong {
      @include font-size(24);
      font-weight: bold;
      margin-right: rem(3);
    }
  }
  &_list {
    margin-bottom: rem(24);
  }
  &_item {
    margin-bottom: rem(32);
    border: 1px solid #cbd0d3;
    padding: rem(20) rem(22) rem(24) rem(24);
    @include mq {
      padding: 0;
      border: none;
      overflow: hidden;
      margin-bottom: rem(24);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.recruit {
  &_ttl {
    @include font-size(24);
    font-weight: bold;
    margin-bottom: rem(12);
    @include mq {
      @include font-size(20);
      margin-bottom: rem(6);
    }
    small {
      @include font-size(20);
      font-family: $font-notoJp;
      @include mq {
        @include font-size(13);
      }
    }
  }
  &_block {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: rem(15);
    @include mq {
      margin-bottom: rem(9);
    }
  }
  &_img {
    width: 40.2%;
    margin-top: rem(4);
    @include mq {
      margin-top: 0;
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
  &_content {
    width: 59.8%;
    padding-left: rem(25);
    @include mq {
      width: 100%;
      padding: rem(8) 0 0;
    }
  }
  &_name {
    @include font-size(13);
    font-weight: bold;
    line-height: 1.55;
    margin-bottom: rem(13);
    @include mq {
      @include font-size(16);
      margin-bottom: rem(8);
      padding-left: rem(8);
      border-left: 3px solid #FFB31E;
    }
  }
  &_point {
    display: none;
    @include mq {
      display: flex;
      align-items: flex-end;
      @include font-size(14);
      color: #fc9c04;
      font-weight: bold;
      border-top: 1px solid #ebeded;
      padding: rem(8) 0 rem(4);

      p {
        padding-left: rem(4);
        line-height: 1;
      }
    }
  }
  &_des {
    font-family: $font-Roboto;
    @include font-size(20);
    line-height: 1.5;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 60px;
    margin-bottom: rem(27);
    @include mq {
      @include font-size(14);
      height: auto;
      margin-bottom: rem(8);
    }
  }
  &_info {
    @include font-size(14);
    line-height: 1.43;
    @include mq {
      @include font-size(13);
    }
    &_closed-search {
      color: red;
      margin-bottom: 2.7rem;
      font-size: 16px;
      @include mq {
        margin-top: 2.7rem;
        color: red;
        font-size: 18px;
      }
    }
  }
  &_field {
    display: flex;
    flex-wrap: wrap;
    @include mq {
      border-top: 1px solid #ebeded;
      padding-top: rem(10);
      padding-bottom: rem(12);
    }
    dt {
      width: 50px;
      font-weight: bold;
      padding-right: rem(10);
      font-size: rem(14);
      @include mq {
        width: 100%;
        margin-bottom: rem(3);
      }
    }
    dd {
      width: calc(100% - 50px);
      @include mq {
        width: 100%;
        @include font-size(12);
        line-height: 1.67;
      }
      strong {
        @include font-size(16);
        line-height: 1.25;
        @include mq {
          @include font-size(16);
          line-height: 1.67;
        }
      }
    }
    &-second {
      @include font-size(13);
      margin-bottom: rem(16);
      dd {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-top: 0;
        @include mq {
          line-height: inherit;
          @include font-size(13);
        }
        p {
          @include mq {
            margin-bottom: rem(-1);
          }
        }
        strong {
          @include font-size(20);
          font-family: $font-Roboto;
          margin-left: 2px;
          @include mq {
            line-height: inherit;
            margin-left: rem(4);
          }
        }
      }
      &-pc {
        display: flex;
        margin-bottom: rem(16);
        @include mq {
          display: none;
        }
      }
      &-sp {
        display: none;
        @include mq {
          display: block;
          .store-details {
            display: flex;
            align-items: flex-start;

            span {
              padding-left: rem(8);
              display: inline-block;
            }
          }
          .salary-details {
            display: flex;
            align-items: flex-start;
          }
        }
      }
    }
    &_description_line {
      min-height: 0.3em;
      line-height: 24px;
      @include mq {
        min-height: 1em;
      }
    }
    &_background_color {
      background-color: #FFEACC;
      border-radius: 0 0 8px 8px;
    }
  }

	&_tag {
		display: inline-block;
		border: 1px solid #FC9C04;
		border-radius: 4px;
		color: #FC9D03;
		vertical-align: bottom;
		@include font-size(11);
		text-align: center;
		padding: rem(2) rem(5);
		margin: 0 0 rem(3) rem(5);
		min-width: 70px;
    @include mq {
      min-width: 10px;
      padding: rem(1) rem(5);
      vertical-align: 1px;
      margin: 0 0 rem(3) rem(5);
      @include font-size(12);
      font-weight: bold;
    }
	}

  &_contact {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      font-size: rem(15);
      width: 32.9%;
      margin: 0.3%;
      @include mq {
        font-size: rem(13);
        width: 49.4%;
      }
      &-more {
        @include mq {
          border-color: #fc9c04;
          color: #fc9c04;
        }
      }
    }
    .short-appeal {
      font-size: 10px
    }
  }

  &_button-pc {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      font-size: rem(15);
      width: 32.9%;
      margin: 0.3%;
      @include mq {
        font-size: rem(13);
        width: 49.4%;
      }
      &-more {
        @include mq {
          border-color: #fc9c04;
          color: #fc9c04;
        }
      }
    }
    .short-appeal {
      font-size: 10px
    }
    @include mq {
      display: none;
    }
  }
  
  &_button-sp {
    display: none;
    @include mq {
      display: flex;
      .detail-button-wrapper,
      .application-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 172px;
        height: 49px;

        .button-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding-right: rem(4);
        }

        .button-text {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        .arrow-icon {
          position: relative;
          left: 15px;
        }
    
        .detail-button,
        .application-button {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          width: 100%;
          height: 100%;
          font-weight: bold;
          text-decoration: none;
          font-size: rem(14);
          border: none;
        }
      }
  
      .detail-button-wrapper {
        border: 2px solid #fc9c04;
        background-color: #fff;
    
        .detail-button {
          color: #fc9c04;
        }
      }
  
      .application-button-wrapper {
        border: none;
        background-color: #fc9c04;
    
        .application-button {
          color: #fff;
        }
      }
    }
    @include mq(ipd) {
      justify-content: center;
    }
  }
}

.franchise_tag {
	@extend .recruit_tag;
	vertical-align: bottom;
	border-color: #04dffc;
	color: #04dffc;
}

.newly_tag {
  @extend .recruit_tag;
  background: #FC9C04;
  color: white;
  margin: 0 0;
  font-weight: bold;
  margin-bottom: rem(8);
  &_big {
    font-size: 16px;
    @include mq {
      @include font-size(13)
    }
  }
}

select.form-control-gray {
  border: 1px solid #ebeded;
  background: white url("/img/recruit/index/dropdown-gray.png") no-repeat 91%;
  padding-right: rem(28);
  @include mq {
    background: white url("/img/recruit/index/dropdown-gray.png") no-repeat 95%;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  @include font-size(16);
  font-weight: bold;
  margin-bottom: rem(48);
  @include mq {
    margin-bottom: rem(32);
  }
  a,
  span {
    float: left;
    padding: 5px;
    width: 48px;
    height: 48px;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s;
    cursor: pointer;
    border-radius: 50%;
    color: #a6adb4;
    margin: 0 rem(1);
    &.active {
      background-color: #a6adb4;
      color: white;
    }
    &:hover {
      opacity: 1;
      &:not(.active) {
        background-color: #ddd;
      }
    }
    &.disabled {
      cursor: default;
      &:hover {
        background-color: transparent;
      }
    }
  }
  .pagination-list {
    &,
    li {
      display: inline-block;
    }
  }
}

.railroad {
  &_ttl {
    @include font-size(16);
    font-weight: bold;
    padding: 12px 4px;

    .icon {
      margin: 0px 4px 4px 0px;
    }
  }
  &_list {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 8px 4px;

    li {
      @include font-size(12);
      padding: 0px 20px 16px 0px;
    }
  }
}
