@charset "utf-8";
@import "reset";
@import "vars";
@import "layout";
@import "module";
// Page styles import
@import "index";
@import "recruit_index";
@import "recruit_detail";
@import "recruit_apply";
@import "about";
@import "appeal";
@import "career";
@import "faq";
@import "college";
@import "brands";
@import "recruit";
@import "recruit_button";
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
