.sec-container {
  display: flex;
  flex-direction: column;

  @include mq() {
    flex-direction: column-reverse;
  }
}

.sec-mv {
  margin-top: rem(80);
  margin-bottom: rem(24);
  @include mq(){
    margin-top: 0;
    margin-bottom: rem(8);
  }
  .slider-home{
    .item{
      outline: none  !important;
      .top-view-image {
        width: 100%;
        max-width: 1160px;
        height: auto;
        margin: 0 auto;
      }
    }
    @include mq(ipd){
      .item {
        .top-view-image {
          width: 95%;
        }
      }
    }
    @include mq(){
      .item {
        .top-view-image {
          width: 95%;
        }
      }
    }
    .slick-arrow{
      width: 48px;
      height: 48px;
      opacity: 1 !important;
      background-size: 100%;
      background-repeat: no-repeat;
      z-index: 1;
      top: 47.2%;
      @include mq(ipd){
        width: 33px;
        height: 33px;
      }
      @include mq(){
        top: 50%;
      }
      &.slick-prev{
        background-image: url("/img/common/prev.png");
        left: 40px;
        @include mq(ipd){
          left: 15px;
        }
        @include mq(){
          left: 0;
        }
      }
      &.slick-next{
        background-image: url("/img/common/next.png");
        right: 40px;
        @include mq(ipd){
          right: 15px;
        }
        @include mq(){
          right: 0;
        }
      }
      &:before{
        display: none;
      }
    }
  }
}

.sec-search {
  position: fixed;
  top: rem(66);
  left: 0;
  right: 0;
  z-index: 998;
  width: 100%;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  background: #FFEACC;
  box-sizing: border-box;
  align-items: center;

  @include mq(){
    position: static;
    box-shadow: none;
    margin: 0 auto;
    width: 95%;
    max-width: 100%;
    border-radius: 5px;
    margin-bottom: rem(24);
  }

  .search-block{
    display: flex;
    max-width: 1160px;
    margin: 0 auto;
    padding: rem(20) 0;
    box-sizing: border-box;
    @include mq(ipd) {
      width: 100%;
      padding: rem(20) rem(20) rem(20);
    }
  
    @include mq() {
      width: 100%;
      padding: rem(10) rem(10) rem(10);
      flex-direction: column;
    }
    .ttl-pc {
      display: flex;
      align-items: center;
      color: #FC9C04;
      margin-left: rem(16);

      span {
        font-size: 20px;
      }

      p {
        font-size: 22px;
        font-weight: bold;
        padding: 0 rem(24) 0 rem(8);

        @include mq(ipd) {
          font-size: 18px;
          padding: 0 rem(8) 0 rem(4);
        }
      }
      @include mq(){
        display: none;
      }
    }
    .ttl-sp {
      display: none;
      @include mq(){
        display: block;
        @include font-size(13);
        font-weight: bold;
        color: #4B4A45;
        margin-bottom: rem(10);
      }
    }
    .form-search{
      flex-grow: 1;
      .fillter{
        .form-control {
          float: left;
          margin-right: rem(8);
          font-weight: 100;
          @include mq(){
            margin-right: rem(0);
          }
        }
        .form-control::placeholder {
          color: #b0b0b0; 
        }
        select{
          width: 24%;
          height: 35px;
          @include mq(){
            width: calc(50% - 0.5rem);
            height: 44px;
          }
          &:nth-child(2){
            width: 24%;
            height: 35px;
            @include mq(){
              width: calc(50% - 0.5rem);
              height: 44px;
              margin-left: rem(8);
            }
          }
        }
        input[type=text]{
          width: 28%;
          height: 35px;
          @include mq(){
            margin: rem(8) 0 rem(16);
            width: 100%;
            height: 44px;
          }
        }
        .btn{
          width: 20%;
          height: 35px;
          float: right;
          @include font-size(15);
          @include mq(){
            width: 100%;
            height: 44px;
            text-align: center;
          }
        }
      }
    }
  }
}

.main-block{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: rem(110);
  @include mq(){
    margin-bottom:rem(62);
  }
  .content-block{
    width: 69%;
    @include mq(ipd){
      width: 69.7%;
    }
    @include mq(){
      width: 100%;
      margin-bottom: rem(8);
    }
  }
  .sidebar{
    width: 28.4%;
    padding: rem(3) 0 0 rem(27);
    border-left: 2px solid #EBEDED;
    margin-bottom: rem(8);
    @include mq(ipd){
      padding-left: rem(15);
    }
    @include mq(){
      width: 100%;
      border-left: none;
      padding-left: 0;
    }
    .box-item {
      .txt-basic {
        .ttl {
          .string{
            -webkit-line-clamp: 2;
            height: 50px;
            @include mq(ipd){
              height: 41px;
            }
            @include mq(){
              height: 50px;
            }
          }
        }
      }
    }
  }
  .content-feature {
    margin-bottom: rem(40);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    .feature-search {
      display:flex;
      flex-wrap: wrap;
      padding: 2% 0;
      .feature-search-block {
        width: 33.3333%;
        padding: 0 3%;
        &:not(:first-of-type) {
          border-left: 1px solid #EBEDED;
        }
        h3 {
          font-size: 15px;
          margin-bottom: rem(5);
          font-weight: bold;
        }
        ol {
          li {
            display: inline-block;
            padding: 0 rem(10) rem(3) 0;
            .link {
              color: #FC9C04;
              font-size: 13px;
              font-weight: bold;
            }
          }
        }
      }
    }
    @include mq(){
      margin-bottom: rem(32);
      .feature-search {
        width: 94%;
        margin: 0 auto;
        display:block;
        padding: 4.5% 4%;
        box-sizing: border-box;
        .feature-search-block {
          width: 100%;
          padding: 0;
          box-sizing: border-box;
          &:not(:first-of-type) {
            border-left: none;
            margin-top: 4.5vw;
          }
          h3 {
            font-size: 15px;
            margin-bottom: 2vw;
            padding-bottom: 2vw;
            border-bottom: 1px solid #EBEDED;
          }
          ol {
            li {
              display: inline-block;
              padding: 0 2vw 1vw 0;
            }
          }
        }
      }
    }
  }
}

.heading-block{
  margin-bottom: rem(20);
  padding-left: rem(7);
  @include mq(){
    padding-left: 0;
    margin-bottom: rem(24);
  }
}

.work-block {
  margin-bottom: rem(55);
}

.work-slider{
  padding: 0 rem(15);
  @include mq(ipd){
    padding: 0;
  }
  @include mq(){
    margin: 0 rem(-10);
  }
  .item{
    outline: none !important;
    padding: rem(10) rem(6);
    @include mq(){
      padding: rem(10);
    }
  }
  .slick-dots{
    left: 9px;
  }
  .slick-arrow{
    width: 31px;
    height: 31px;
    opacity: 1 !important;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 1;
    top: 48.5%;
    @include mq(){
      top: 49.5%;
    }
    &.slick-prev{
      background-image: url("/img/common/prev.png");
      left: 0;
      @include mq(ipd){
        left: -10px;
      }
      @include mq(){
        left: -5px;
      }
    }
    &.slick-next{
      background-image: url("/img/common/next.png");
      right: 0;
      @include mq(ipd){
        right: -10px;
      }
      @include mq(){
        right: -5px;
      }
    }
    &:before{
      display: none;
    }
  }
}
.box-item{
  border-radius: 7px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
  line-height: 1.6;

  @include mq() {
    border-radius: 0;
    box-shadow: none;
  }
  .img{
    img{
      width: 100%;
    }
  }
  .txt-basic{
    background: white;
    padding: rem(13) rem(10) 0;
    @include mq(){
      display: none;
    }
    .ttl{
      display: block;
      font-weight: bold;
      @include font-size(16);
      border-bottom: 1px solid #EBEDED;
      padding-bottom: rem(5);
      margin-bottom: rem(10);
      @include mq(){
        padding-bottom: 0;
        margin-bottom: rem(8);
      }

      .string{
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        height: 50px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        @include mq(ipd){
          height: 41px;
        }
        @include mq(){
          height: 50px;
        }
      }
      @include mq(ipd){
        @include font-size(13);
      }
      @include mq(){
        @include font-size(16);
      }
      small{
        display: block;
        @include font-size(12);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @include mq(ipd){
          @include font-size(11);
        }
        @include mq(){
          @include font-size(12);
        }
      }
    }
    .coint{
      border-bottom: 1px solid #EBEDED;
      padding-bottom: rem(12);
      height: rem(85);
      margin-bottom: rem(15);
      @include mq(){
        margin-bottom: rem(11);
      }
      dl{
        display: flex;
        align-items: center;
        padding-left: rem(2);
        line-height: 1;
        margin-bottom: rem(9);
        @include mq(){
          margin-bottom: rem(6);
        }
        &:last-child{
          margin-bottom: 0;
        }
        dt{
          border: 1px solid #FC9C04;
          color: #FC9D03;
          border-radius: 4px;
          overflow: hidden;
          width: 71px;
          @include font-size(11);
          padding: rem(4) 0;
          text-align: center;
        }
        dd{
          width: calc(100% - 71px);
          padding-left: rem(10);
          @include font-size(13);
          @include mq(ipd){
            @include font-size(11);
            padding-left: rem(5);
          }
          @include mq(){
            @include font-size(13);
            padding-left: rem(14);
          }
          span{
            font-weight: bold;
            font-family: $font-Roboto;
            @include font-size(20);
            @include mq(ipd){
              @include font-size(14);
            }
            @include mq(){
              @include font-size(20);
            }
          }
        }
      }
    }
    .note{
      padding-bottom: rem(10);
      margin-bottom: rem(8);
      border-bottom: 1px solid #EBEDED;
      @include font-size(12);
      strong{
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  .conversion{
    background: white;
    padding: 0 rem(10) rem(13);
    @include mq(){
      display: none;
    }
    .link-item{
      display: flex;
      justify-content: space-between;
      li{
        width: 49%;
        @include mq(ipd){
          .btn{
            height: 40px;
            @include font-size(12);
          }
        }
        @include mq(){
          .btn{
            height: 60px;
            @include font-size(13);
          }
        }
        .short-appeal {
          font-size: 10px;
        }
      }
    }
  }
}

.txt-basic-pc {
  display: block;
  padding: rem(8) 0 0 0;
  @include mq() {
    display: none;
  };
  .ttl-pc {
    @include font-size(16);
    font-weight: bold;
    margin-bottom: rem(8);
    padding-left: rem(8);
    padding-bottom: 0;
    border-left: 3px solid #FFB31E;
    border-bottom: none;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .point-pc {
    display: flex;
    align-items: flex-end;
    @include font-size(14);
    color: #fc9c04;
    font-weight: bold;
    border-top: 1px solid #ebeded;
    padding: rem(8) 0 rem(4);

    p {
      padding-left: rem(4);
      line-height: 1;
    }
  }
  .des-pc {
    font-family: $font-Roboto;
    @include font-size(20);
    line-height: 1.5;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 60px;
    margin-bottom: rem(27);
    @include font-size(14);
    height: auto;
    margin-bottom: rem(8);
  }
  .details-pc {
    display: flex;
    align-items: flex-start;
    border-top: 1px solid #ebeded;
    padding-top: rem(10);
    padding-bottom: rem(12);
    span {
      @include font-size(12);
      padding-left: rem(8);
      line-height: 1.67;
    }
  }
  .salary-pc {
    display: flex;
    align-items: flex-start;
    border-top: 1px solid #ebeded;
    padding-top: rem(10);
    padding-bottom: rem(12);
  
    .salary-text-pc {
      display: flex;
      flex-direction: column;
      min-height: rem(60);
      @include font-size(12);

      span {
        @include font-size(16);
      }
  
      .tag-pc {
        display: inline-block;
        border: 1px solid #FC9C04;
        border-radius: 4px;
        color: #FC9D03;
        vertical-align: bottom;
        @include font-size(11);
        text-align: center;
        margin: 0 0 rem(3) rem(5);
        min-width: 10px;
        padding: rem(2) rem(5);
        vertical-align: 1px;
        @include font-size(12);
        font-weight: bold;
      }
    }
  }
  .button-pc {
    display: block;
    @include mq () {
      display: none;
    }
  }
}

.txt-basic-sp {
  display: none;
  @include mq() {
    display: block;
    padding: rem(8) 0 0 0;
    line-height: 1.6;
  };
  .ttl-sp {
    @include font-size(16);
    font-weight: bold;
    margin-bottom: rem(8);
    padding-left: rem(8);
    padding-bottom: 0;
    border-left: 3px solid #FFB31E;
    border-bottom: none;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .point-sp {
    display: none;
    @include mq {
      display: flex;
      align-items: flex-end;
      @include font-size(14);
      color: #fc9c04;
      font-weight: bold;
      border-top: 1px solid #ebeded;
      padding: rem(8) 0 rem(4);

      p {
        padding-left: rem(4);
        line-height: 1;
      }
    }
  }
  .des-sp {
    display: none;
    @include mq {
    font-family: $font-Roboto;
    @include font-size(20);
    line-height: 1.5;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 60px;
    margin-bottom: rem(27);
      @include font-size(14);
      height: auto;
      margin-bottom: rem(8);
    }
  }
  .details-sp {
    display: flex;
    align-items: flex-start;
    border-top: 1px solid #ebeded;
    padding-top: rem(10);
    padding-bottom: rem(12);
    span {
      @include font-size(12);
      padding-left: rem(8);
      line-height: 1.67;
    }
  }
  .salary-sp {
    display: flex;
    align-items: flex-start;
    border-top: 1px solid #ebeded;
    padding-top: rem(10);
    padding-bottom: rem(12);
  
    .salary-text-sp {
      display: flex;
      flex-direction: column;
      min-height: rem(60);
      @include font-size(12);

      span {
        @include font-size(16);
      }
  
      .tag-sp {
        display: inline-block;
        border: 1px solid #FC9C04;
        border-radius: 4px;
        color: #FC9D03;
        vertical-align: bottom;
        @include font-size(11);
        text-align: center;
        padding: rem(2) rem(5);
        margin: 0 0 rem(3) rem(5);
        min-width: 70px;
        @include mq {
          min-width: 10px;
          padding: rem(1) rem(5);
          vertical-align: 1px;
          margin: 0 0 rem(3) rem(5);
          @include font-size(12);
          font-weight: bold;
        }
      }
    }
  }
  .button-sp {
    display: none;
    @include mq () {
      display: block;
    }
  }
}

.nearest-item{
  margin-bottom: rem(60) !important;

  @include mq(ipd){
    padding: 0;
  }
  @include mq(){
    margin: 0 rem(-10);
  }
  .item{
    padding: rem(10) rem(17);
    @include mq(ipd){
      padding: rem(10);
    }
    .box-item{
      .img{
        img{
          width: 100%;
        }
      }
      .txt-basic{
        padding: rem(11);
        .ttl{
          border-bottom: none;
        }
      }
    }
  }
  .slick-dots{
    li {
      @include mq(md-min){
        margin: 0 rem(1);
      }
    }
  }
  .slick-arrow{
    width: 31px;
    height: 31px;
    opacity: 1 !important;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 1;
    top: 48.5%;

    &.slick-prev{
      background-image: url("/img/common/prev.png");
      left: 4px;
      @include mq(ipd){
        left: -5px;
      }
    }
    &.slick-next{
      background-image: url("/img/common/next.png");
      right: 0;
      @include mq(ipd){
        right: -5px;
      }
    }
    &:before{
      display: none;
    }
  }
}


.fillter-item{
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: rem(29) 0 rem(7) rem(9);
  margin-bottom: rem(40);
  @include mq(){
    box-shadow: 0 0 0 0 black;
    padding: 0;
    border-radius: 0;
    margin-bottom: rem(32);
  }
  .heading-block{
    @include mq(md-min){
      margin-left: rem(20);
      margin-bottom: rem(20);
    }
  }
  .show-fillter{
    font-weight: bold;
    @include font-size(16);
    dl{
      display: flex;
      line-height: 1;
      margin-bottom: rem(19);
      @include mq(){
        flex-wrap: wrap;
        border-bottom: 1px solid #CBD0D3;
        padding-bottom: rem(5);
        display: none;
        &:nth-child(1),&:nth-child(2),&:nth-child(3){
          display: flex;
        }
        &:nth-child(3){
          border-bottom: none;
        }
        &:last-child{
          padding-bottom: 0;
          border-bottom: none;
        }
      }
      dt{
        width: 113px;
        text-align: right;
        color: #202124;
        @include mq(){
          width: 100%;
          text-align: left;
          margin-bottom: rem(11);
        }
      }
      dd{
        width: calc(100% - 113px);
        padding-left: rem(17);
        @include mq(){
          width: 100%;
          padding-left: 0;
        }
        ul{
          display: flex;
          flex-wrap: wrap;
          li{
            margin-right: rem(27);
            margin-bottom: rem(10);
            @include mq(){
              margin-bottom: rem(15);
            }
            a{
              color: #FC9C04;
              span{
                color: #A6ADB4;
                font-weight: 400;
                @include font-size(13);
              }
              &.disabled{
                color: #CBD0D3;
                pointer-events: none;
              }
            }
          }
        }
      }
    }
    &.active{
      @include mq(){
        dl{
          display: flex;
          &:nth-child(3){
            border-bottom: 1px solid #CBD0D3;
          }
        }
      }
    }
  }
}

.fillter-trademark{
  margin-bottom: rem(40);
  .quarter_flex{
    margin-bottom: 10rem;
  }
  @include mq(){
    margin-bottom: rem(32);
  }
}
.trademark-item{
  display: flex;
  flex-wrap: wrap;
  margin: rem(10) rem(-10) 0;
  .item{
    width: 50%;
    padding: 0 rem(10);
    margin-bottom: rem(20);
    @include mq(md-min){
      &:nth-child(3),&:nth-child(4){
        margin-bottom: 0;
      }
    }
    @include mq(){
      width: 100%;
      margin-bottom: rem(10);
      &:last-child{
        margin-bottom: 0;
      }
    }
    .trademark-block{
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
      padding: rem(24) rem(13) rem(11) rem(18);
      border: 1px solid #CBD0D3;
      border-radius: 7px;
      .img{
        margin-bottom: rem(10);
        img{
          height: 30px;
          display: block;
          margin: 0 auto;
        }
      }
      .brand_img {
        @extend .img;
        img {
          height: 50px;
        }
      }
      .txt-basic{
        line-height: rem(15);
        @include font-size(12);
        p{
          margin-bottom: rem(22);
          height: rem(36);
        }
      }
      &_reraku{
        @extend .trademark-block;
        box-shadow: none;
        border: 1px solid #97c736;
      }
      &_spa{
        @extend .trademark-block;
        box-shadow: none;
        border: 1px solid #ff782a;
      }
      &_bell{
        @extend .trademark-block;
        box-shadow: none;
        border: 1px solid #7ba428;

      }
      &_ruam{
        @extend .trademark-block;
        box-shadow: none;
        border: 1px solid #d3bd73;

      }
    }
  }
  .quarter_item {
    width: 25%;
    @extend .item;
    @include mq(ipd){
      width: 100%;
      margin-bottom: rem(10);
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.contact-block{
  display: flex;
  flex-wrap: wrap;
  color: white;
  margin-bottom: rem(34);
  @include mq(ipd){
    align-items: center;
    background: #FC9C04;
  }
  @include mq(){
    padding: rem(15) rem(15) rem(25);
    margin: 0 rem(-15);
  }
  .note{
    width: 43%;
    padding-top: rem(55);
    background: #FC9C04;
    @include mq(ipd){
      padding: rem(20);
    }
    @include mq(){
      width: 100%;
      order: 2;
      padding: rem(20) 0 0;
    }
    .ttl{
      display: block;
      @include font-size(31);
      font-weight: bold;
      text-align: center;
      margin-bottom: rem(24);
      @include mq(ipd){
        @include font-size(25);
      }
      @include mq(){
        @include font-size(30);
        margin-bottom: rem(24);
      }
      @include mq(xs){
        @include font-size(28);
      }
      small{
        display: block;
        @include font-size(15);
        letter-spacing: 3px;
      }
    }
    .register-contact{
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid white;
      width: 235px;
      height: 60px;
      @include font-size(13);
      color: white;
      border-radius: 40px;
      margin: 0 auto rem(14);
      @include mq(){
        width: 257px;
      }
      img{
        margin-right: rem(10);
      }
    }
    .tel-block{
      @include font-size(12);
      font-weight: 400;
      max-width: 238px;
      margin: 0 auto;
      font-family: $font-Roboto;
      @include mq(){
        .txt-right{
          text-align: center;
        }
      }
      .tel{
        display: flex;
        align-items: center;
        @include font-size(32);
        font-weight: bold;
        color: white;
        img{
          margin-right: rem(5);
        }
      }
    }
  }
  .img{
    width: 57%;
    @include mq(){
      order: 1;
      width: 100%;
    }
    img{
      width: 100%;
      @include mq(ipd){
        min-height: 280px;
        object-fit: cover;
        font-family: 'object-fit: cover';
      }
      @include mq(){
        border-radius: 10px;
        min-height: 100px;
      }
    }
  }
}

.nearest-block-item{
  .item{
    margin-bottom: rem(17);
    @include mq(){
      margin-bottom: rem(15);
    }
    &:last-child{
      margin-bottom: 0;
    }
    a{
      display: block;
      .img{
        position: relative;
        img{
          width: 100%;
          height: auto;
          border-radius: 10px;
          box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.26);
        }
        .ttl{
          position: absolute;
          bottom: 16px;
          left: 18px;
          color: #202124;
          span{
            display: block;
            &.en{
              @include font-size(11);
            }
            &.jp{
              @include font-size(21);
            }
          }
        }
      }
    }
  }
}

@include mq(md-min){
  .more-show-fillter{
    display: none !important;
  }
}
.more-show-fillter{
  position: relative;
  box-shadow: 0 0 0 0 black;
  span{
    &.open{

    }
    &.close{
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      opacity: 0;
      visibility: hidden;
    }
  }
  &.active{
    span{
      &.open{
        opacity: 0;
        visibility: hidden;
      }
      &.close{
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.side-nav-img {
  background: #ffffff;
  border-radius: 10px;
  img {
    opacity: 0.6;
  }
}

.newly-opend-store-date {
  margin-bottom: 10px;
  @include font-size(20);
  color: orange;
  font-size: x-large;
  &-small {
    @include font-size(12);
    margin-left: 5px;
    font-style: italic;
    font-size: medium;
  }
}