
a{
	text-decoration: none;
	@include smooth-transition;
	&:hover{
		opacity: 0.7;
	}
}
strong{
	font-weight: bold;
}

.heading{
	&-page{

	}
	&-block{
		display: block;
		@include font-size(20);
		font-weight: bold;
		color: #202124;
		font-family: $font-notoJp;
		margin-bottom: rem(20);
		@include mq(){
			margin-bottom: rem(13);
		}
	}
	&-item{

	}
}

.ttlCom01 {
	@include font-size(40);
	font-family: $font-notoJp;
	line-height: 1.2;
	font-weight: bold;
	color: #202124;
	@include mq(lg){
		@include font-size(30);
	}
	@include mq{
		@include font-size(26);
	}
	@include mq(sm){
		@include font-size(24);
	}
	@include mq(xs){
		@include font-size(21);
	}
}

.ttlCom02 {
	@include font-size(20);
	font-family: $font-notoJp;
	line-height: 1.5;
	font-weight: bold;
	color: #202124;
	@include mq{
		@include font-size(18);
		line-height: 1.4;
	}
}

.ttlCom03 {
	@include font-size(30);
	line-height: calc(34/30);
	font-weight: bold;
	@include mq{
		@include font-size(24);
		line-height: calc(34/24);
	}

}
.ttlCom04 {
	@include font-size(24);
	line-height: calc(34/24);
	font-weight: bold;
	@include mq{
		@include font-size(24);
		line-height: calc(34/24);
	}

}
.ttl_type01 {

}
.ttl_type02{

}
.ttl_type03{

}


.txt{
	&-basic{
		.ttl-small{
			display: block;
			@include font-size(13);
			font-weight: bold;
			margin-bottom: rem(12);
			line-height: 1;
			@include mq(){
				margin-bottom: rem(10);
			}
		}
		&_common{
			@include font-size(13);
			line-height: calc(20/ 13);
			font-weight: 400;
			@include mq{
				@include font-size(13);
				line-height: calc(20/ 13);
				font-weight: 400;
			}
		}
	}
	&-center {
		text-align: center;
	}
	&-right {
		text-align: right;
	}
	&-left {
		text-align: left;
	}
}

.btn{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 44px;
	border: 2px solid #A6ADB4;
	background: white;
	color: #A6ADB4;
	@include font-size(13);
	font-weight: bold;
	outline: none;
	border-radius: 40px;
	box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.25);
	cursor: pointer;
	text-align: center;
	&:hover{
		border: 2px solid #B3B9BF;
	}
	&.btn-big{
		height: 60px;
		@include mq(xs){
			display: flex;
		}
	}
	&.btn-grey{
		background: #707070;
		border: 2px solid #707070;
	}
	&.btn-orange{
		border: 2px solid #FC9C04;
		background: #FC9C04;
		color: white;
		&:hover{
			opacity: 1;
			background: #FDAC29;
			border: 1px solid #FC9C04;
		}
	}
}

button.btn {
	@include mq(xs) {
		display: block !important;
	}
}


.more-link{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	@include font-size(12);
	color: #202124;
	letter-spacing: -1px;
	position: relative;
	padding-right: rem(24);
	&:after{
		content: "";
		width: 16px;
		height: 16px;
		background: #343638 url("/img/common/next-small.png")no-repeat;
		background-position: center center;
		border-radius: 50%;
		display: block;
		position: absolute;
		right: 0;
		@include v-align;
	}
	img{
		margin-left: rem(7);
	}
}

select{
	&.form-control{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		background: white url("/img/common/dropdown.png") no-repeat 91%;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		&::-ms-expand {
			display: none;
		}
	}
}
.form-control{
	display: block;
	width: 100%;
	height: 44px;
	background: white;
	border: none;
	border-radius: 4px;
	line-height: 1;
	color: #4B4A45;
	font-weight: bold;
	@include font-size(13);
	padding: 0 rem(15);
	&::-webkit-input-placeholder {
		color: #EBEDED;
	}
	&:-moz-placeholder {
		color: #EBEDED;
	}
	&::-moz-placeholder {
		color: #EBEDED;
	}
	&:-ms-input-placeholder {
		color: #EBEDED;
	}
}
