/* ====================================================
Base style & container
==================================================== */

body {
	color: #202124;
	font-family: $font-notoJp;
	font-size: 1.6em;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
	overflow-x: hidden;
}

.inner {
	display: block;
	max-width: 1190px;
	padding: 0 rem(15);
	margin: 0 auto;
	#job_anchor {
		padding-left: rem(10);
	}
}
.inner-960{
	display: block;
	max-width: 990px;
	padding: 0 rem(15);
	margin: 0 auto;
}

.pc {
	@include mq(){
		display: none !important;
	}
}

.sp {
	@include mq(md-min){
		display: none !important;
	}
}

.clearfix {
	*zoom: 1;
	&:before {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
		content: "";
		display: table;
	}
}

.environment-notification {
	background-color:red;
	width:100%;
	height:3%;
	padding:0;
	top: 0;
	color:white;
	text-align:center;
	position: fixed;
	z-index: 1000;
}

//header
#header{
	background: white;
	padding: rem(10) 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	.inner{
		display: flex;
		padding: 0;
		max-width: 1160px;
		align-items: center;

		@include mq(ipd) {
			width: 100%;
			padding: 0 rem(30);
		}
	
		@include mq() {
			width: 100%;
			padding: 0 rem(10);
		}
	}
	.logo{
		display: inline-block;
    max-width: 300px;
		margin-right: auto;
		@include mq(){
			margin-bottom: 0;
			max-width: 240px;
		}
	}
	.box-wp{
		@include mq(){
			height: 100%;
			overflow: auto;
			padding-bottom: rem(20);
		}
	}

	.nav-menu{
		@include mq(){
			background: #F6F8F9;
			position: fixed;
			top: 0;
			right: -285px;
			bottom: 0;
			width: 285px;
			@include smooth-transition;
			z-index: 999;
			//@media only screen and (min-width: 450) and (max-width: 750) {
			//	overflow-y: scroll;
			//	padding-bottom: rem(20);
			//}
			&.active{
				right: 0;
				box-shadow: 0 9px 14px 15px rgba(0, 0, 0, 0.25);
				&:before{
					content: "";
					width: 500%;
					position: absolute;
					bottom: 0;
					right: 100%;
					top: 0;
					background: rgba(252, 156, 4, 0.2);
				}
			}
		}
		.menu-odd{
			display: none;
			@include mq(){
				display: block;
				padding-top: rem(10);
			}
			.item{
				padding: rem(10) rem(15);
				a{
					display: block;
					position: relative;
					@include font-size(14);
					font-weight: bold;
					color: #4B4A45;
				}
			}
		}
		.show-menu{
			display: flex;
			margin-left: auto;
			@include mq(){
				display: block;
				background: white;
				padding-top: rem(10);
			}
			.item{
				margin-left: rem(32);
				&.item-sp{
					display: none;
					@include mq(){
						display: block;
						a{
							padding-left: 0;
							img{
								position: static;
								top: 0;
								-webkit-transform: translateY(0);
								-ms-transform: translateY(0);
								transform: translateY(0);
							}
						}
					}
				}
				@include mq(){
					width: 100%;
					padding: rem(10) rem(15);
					margin-left: 0;
				}
				a{
					display: flex;
					@include font-size(14);
					color: #202124;
					position: relative;
					text-align: center;
					&:hover{
						color: #414245;
					}
					@include mq(){
						font-weight: bold;
						text-align: left;
						padding-left: rem(43);
					}
					img{
						width: 20px;
						height: 20px;
						display: block;
						padding-right: rem(4);
						@include mq(){
							position: absolute;
							left: 0;
							width: 25px;
							height: 25px;
							padding-right: 0;
							@include v-align;
						}
					}
					span{
						display: block;
						white-space: nowrap;
					}
				}
			}
		}
	}
	.mobile-icon {
		width: 30px;
		height: 30px;
		position: absolute;
		@include v-align;
		right: 12px;
		display: none;
		@include smooth-transition;
		z-index: 9999;
		@include font-size(10);
		text-align: center;
		@include mq(){
			display: block;
		}
		> span {
			@include smooth-transition;
			&.open{
				img {
					margin-bottom: rem(2);
				}
			}
			&.close{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				opacity: 0;
				visibility: hidden;
			}
		}
		&.mobile-close{
			right: 290px;
			> span {
				&.open{
					opacity: 0;
					visibility: hidden;
				}
				&.close{
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

//footer
#footer{
	padding-bottom: rem(115);
	position: relative;
	@include mq(){
		padding: rem(40) 0 rem(80);
	}
	.inner{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 rem(47) 0 rem(68);
    .logo {
      max-width: 400px;
    }
		@include mq(ipd){
			padding: 0 rem(15);
		}
		@include mq(){
			flex-wrap: wrap;
			.logo{
				max-width: 120px;
				margin-bottom: rem(30);
				padding-left: rem(20);
				max-width: 240px;
			}
			.item-footer{
				width: 100%;
			}
		}
		.menu-footer{
			display: flex;
			justify-content: flex-end;
			margin-bottom: rem(7);
			@include mq(){
				flex-wrap: wrap;
				justify-content: flex-start;
			}
			li{
				margin-right: rem(17);
				@include mq(){
					margin-bottom: rem(10);
				}
				&:last-child{
					margin-right: 0;
				}
				a{
					font-family: $font-notoJp;
					color: #4B4A45;
					@include font-size(14);
					font-weight: bold;
				}
			}
			&.jp{
				margin-bottom: rem(30);
				@include mq(){
					max-width: 100%;
				}
				li{
					@include mq(){
						margin-bottom: 0;
					}
					a{
						font-family: $font-notoJp;
						font-weight: 400;
					}
				}
			}
		}
		.copyright{
			text-align: right;
			@include font-size(11);
			color: #4B4A45;
			letter-spacing: 0.1px;
			font-family: $font-gothic2;
			@include mq(){
				border-top: 1px solid #A6ADB4;
				padding: rem(15) 0 0 rem(15);
				text-align: left;
			}
		}
	}
	#back-top{
		position: fixed;
		bottom: 20px;
		right: 15px;
		z-index: 9;
		@include mq(){
			bottom: 85px;
		}
	}
}

.main{
	margin-top: rem(58);
	@include mq(){
		margin-top: rem(55);
	}
}

@include mq(md-min){
	a[href^="tel"] {
		pointer-events: none;
	}
}
html.is-locked {
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;
	body{
		-webkit-overflow-scrolling: auto;
	}
}

.external-link {
  text-decoration: underline;
  color: #0000ee;
  font-size: 1.4rem;
  &:after {
    margin-bottom: .5rem;
    width: 2rem;
    height: 2rem;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    content: url("/img/common/external-link-alt-solid.svg");
  }
}
