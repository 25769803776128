.college-others {
  text-align: center;
  &-heading {
    @include font-size(30);
    display: inline-block;
    font-weight: bold;
    border-bottom: 1px solid #FC9C04;
    border-top: 1px solid #FC9C04;
    padding: 40px 0;
    @include mq(lg){
      margin-bottom: 40px;
      font-size: 2.4rem;
    }
  }

  &-des {
    display: inline-block;
    text-align: left;
    padding: 40px 200px;

    @include mq(lg){
      padding: 20px 0;
    }
    h4 {
      font-weight: bolder;
      font-size: 18px;
      padding-bottom: 5px;
      @include mq(lg){
        font-size: 18px;
    }
  }
  a{
    color: #FC9C04;
  }
}

.college-address-card {
  @include mq(lg-min) {
  margin-top: 5rem;
}
  .box-item {
    display: flex;
    flex-direction: column; 
    text-align: left;
    margin: 0.5rem;
    @include mq(lg-min) {
      flex-direction: row; 
      align-items: center;
      justify-content: space-between;
      width: 70%;
      margin: 10px auto;
      // height: 250px;
    }
    .college-address-text {
      margin: 10px;
      .college-address-heading{
        font-weight: bold;
        color: #FC9C04;
      }
      .college-address-subheading {
        font-size: 12px;
        font-weight: bold;
      }
      .college-address-description {
        font-size: 12px;
        padding-left: 1em;
      }
    }
    .college-map-button {
      width: 90%; 
      margin: 20px auto;
      @include mq(lg-min) {
        width: 100%;
        max-width: 200px;
        margin: auto 60px;
      }
      .btn {
        font-size: 15px;
      }
    }
  }
}
  &-contents{
    .college-others-content
    {
      .college-movie-title{
          font-size: 28px;
          @include mq(lg){
            font-size: 16px;
          }
      }
      .college-movie-txt{
        padding: 200px 0;
        font-size: 32px;

        @include mq(lg){
          font-size: 24px;
          padding: 160px 0;
        }
      }
      .college-movie-link {
        width: 100%;
        iframe{
          text-align: center;
          margin: 60px 0;
          padding: 0 60px;
          display: block;
          width: 100%;
          height: 560px;
        }
        @include mq(lg){
          position: relative;
          height: 0;
          width: 100%;
          padding-top: 60%;
          iframe {
            margin: 0;
            padding: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        display: inline-block;
        text-align: center;
        @include mq(lg){
          margin: 20px 0;
        }
        &__map{
          iframe{
            margin-bottom: 60px;
            height: 300px;
            width: 100%;
          }
        }
      }
    }
  }
}

.interview{
  margin:auto;
  max-width: 760px;
  display: flex;
  flex-wrap: nowrap;
  padding-top: 10px;
  margin-bottom: 15px;
  &-balloon-left{
    position: relative;
    padding: 20px;
    border-radius: 10px;
    color: rgba(51,51,51,1);
    background: rgba(252,156,4,0.5);
    margin-bottom: 20px;
    margin-right:10px;
    left: 20px;
    width: 75%;

    &::before{
      content: '';
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      left: -15px;
      top: 20px;
      border-right: 15px solid rgba(252,156,4,0.5);
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
  }
  &-icon-left{
    position: relative;
    top: 0;
    max-width: 150px;
    max-height: 150px;
    @include mq(sm){
      width: 80px;
      height: 80px;
    }
  }
  &-balloon-right{
    position: relative;
    padding: 20px;
    border-radius: 10px;
    color: rgba(51,51,51,1);
    background: rgba(252,156,4,0.5);
    margin-bottom: 20px;
    margin-left:10px;
    right: 20px;
    width: 75%;

    &::before{
      content: '';
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      right: -15px;
      top: 20px;
      border-left: 15px solid rgba(252,156,4,0.5);
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
  }
  &-icon-right{
    position: relative;
    top: 0;
    max-width: 150px;
    max-height: 150px;
    @include mq(sm){
      width: 80px;
      height: 80px;
    }
  }
}


.college-point {
  margin-bottom: 3rem;
  @include mq(lg-min) {
    display: flex;
    column-gap: 3rem;
  }
  &-appeal {
    flex: 1;
    margin-bottom: 3rem;
    &-sec {
      margin: 5px;
      h3 {
        padding: 15px;
        font-weight: bold;
        background-color: rgba(252, 191, 4, 0.3);
      }
      &-txt{
        padding: 10px;
        margin-top: 5px;
        p{
          font-size: 16px;
        }
        @include mq(sm-min) {
          height: 15rem;
        }
        @include mq(md-min) {
          height: 17rem;
        }
        @include mq(lg-min) {
          height: 18rem;
        }
        @include mq(lg) {
          height: auto;
        }
      }
      .item_img {
        @include mq(md-min) {
          width: 100%;
          position: relative;
          &:before {
            content:"";
            display: block;
            padding-top: 60%;
          }
          img {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }
}

.points-label {
  padding: 1rem 2rem;
  margin: 1rem;
  border-left: 3px solid #fba123;
  font-size: 18px;
  font-weight: bold;
}

.college-step{
  margin-bottom: 3rem;
  h2 {
    text-align: center;
    font-weight: bold;
    padding: 2rem 0;
    font-size: 20px;
  }
  &-bar {
    margin: 0 auto;
    width: 80%;
    &-wrap {
      margin: 2em 0;
      position: relative;
    }
    &-steptitle {
      display: inline-flex;
      align-items: center;
    }
    &-stepcircle {
      display: inline-block;
      width: 3em;
      height: 3em;
      content: "";
      border-radius: 50%;
      background-color: #fba123;
      color: #fff;
      text-align: center;
      span {
        display: inline-block;
        line-height: 1.2em;
        font-size: 0.8em;
        font-weight: bold;
        position: relative;
        top: 0.9em;
      }
    }
    &-title {
      margin: 0.5em;
      font-weight: bold;
      font-size: 1.2em;
    }
    &-steptxt {
      padding-left: 3.5em;
    }
    &-txt {
      font-size: 0.9em;
    }
    &-stepline {
      width: 1px;
      height: calc(100% + 1em);
      background-color:#fba123;
      position: absolute;
      top: 2em;
      left: 1.5em;
      z-index: -1;
    }

    &-wrap:last-of-type {
      .college-step-bar-stepline {
        display: none;
      }
    }
  }
}
