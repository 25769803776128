.sec-form-main {
	padding: rem(30) 0 rem(50);
	@include mq() {
		padding: rem(15) 0 0;
	}
}

.ttl-form {
	font-family: $font-notoJp;
	@include font-size(24);
	font-weight: bold;
	line-height: 1.5;
	margin: rem(11) 0;
	color: #202124;
	@include mq() {
		@include font-size(16);
		margin-bottom: rem(10);
	}
}

.form {
	.list-input-group {
		border: 1px solid #A6ADB4;
		border-radius: 8px;
		margin-bottom: rem(22);
		@include mq() {
			margin-bottom: rem(10);
			padding-bottom: rem(4);
			border-radius: 0;
			border: none;
			border-top: 2px solid #EBEDED;
		}

		.item {
			display: flex;
			flex-wrap: wrap;
			border-bottom: 1px solid #A6ADB4;
			@include mq() {
				margin: 1rem 0;
				border-bottom: none;
			}

			&:first-of-type {
				.item_label {
					border-radius: 8px 0 0 0;
				}

				.item_input {
					border-radius: 0 8px 0 0;
				}
			}

			&:last-of-type {
				border-bottom: none;

				.item_label {
					border-radius: 8px 0 0 8px;
				}

				.item_input {
					border-radius: 0 0 8px 0;
				}
			}

			&_label {
				background: #FFFBE8;
				width: 160px;
				padding: rem(20) rem(15);
				border-right: 1px solid #A6ADB4;
				&_subtext {
					font-family: $font-notoJp;
					@include font-size(11);
					color: #333;

				}

				@include mq() {
					width: 100%;
					padding: 0;
					background: none;
					border: none;
					margin-bottom: rem(6);
				}

				span {
					font-family: $font-notoJp;
					@include font-size(14);
					font-weight: bold;
					color: #202124;
					@include mq() {}
				}

				sub {
					color: #FF4D4D;
					@include font-size(14);
				}


			}

			//resume
			.confirm_title {
				@include mq() {
					border-bottom: solid 1px #000;
					padding-bottom: 4px;
				}
			}

			&_input {
				width: calc(100% - 160px);
				padding: rem(10) rem(15) rem(5);
				@include mq() {
					width: 100%;
					padding: 0;
					border-radius: 0 !important;
				}

				//resume
				.prof_image {
					width: 20%;
				}



				.input-group {
					.input {
						max-width: 360px;
						width: 100%;
						height: 44px;
						border: 2px solid #EBEDED;
						border-radius: 6px;
						padding: 0 rem(15);
						@include font-size(14);
						color: #202124;
						outline: 0;
						@include mq() {
							max-width: 100%;
						}
						//resume
						&_textarea {
							width: 200%;
							height: 160px;
							border: 2px solid #EBEDED;
							border-radius: 6px;
							padding: 0 rem(15);
							@include mq() {
								max-width: 100%;
							}
						}

						&::placeholder {
							font-family: $font-notoJp;
							@include font-size(13);
							color: #A6ADB4;
							opacity: 1;
						}

						&:-ms-input-placeholder {
							font-family: $font-notoJp;
							@include font-size(13);
							color: #A6ADB4;
						}

						&::-ms-input-placeholder {
							font-family: $font-notoJp;
							@include font-size(13);
							color: #A6ADB4;
						}
					}
				}

				.job_posting {
					padding: rem(11);
					font-weight: bold;
					color: #202124;
					width: 100%;
					height: 44px;
					@include mq() {
						padding: initial;
						height: initial;
					}
				}

				.input-radio {
					display: flex;

					.input_radio {
						width: 175px;
						padding: rem(13);
						font-family: $font-notoJp;
						@include font-size(12);
						color: #A6ADB4;
						font-weight: bold;
						@include mq() {
							width: 49.5%;
							padding: 0;
							margin-right: rem(2);

							&:last-of-type {
								margin-right: 0;
							}
						}

						input {
							display: none;
							width: 100%;

							&:checked {
								&+label {
									color: #202124;
									@include mq() {
										color: #fff;
										background: #FC9D03;
										display: flex;
										height: 44px;
										align-items: center;
										border-radius: 8px;
										border: none;
									}

									&:before {
										background: url("/img/recruit/apply/ico-radio-check.png") no-repeat;
										background-size: 100% 100%;
										@include mq() {
											background: none;
										}
									}
								}
							}
						}

						label {
							position: relative;
							padding-left: rem(25);
							cursor: pointer;
							@include mq() {
								padding-left: rem(15);
								color: #A6ADB4;
								background: #fff;
								border: 1px solid #EBEDED;
								border-radius: 5px;
								display: flex;
								height: 44px;
								align-items: center;
							}

							&:before {
								content: "";
								position: absolute;
								width: 15px;
								height: 15px;
								top: 2px;
								left: 0;
								background: url("/img/recruit/apply/ico-radio.png") no-repeat;
								background-size: 100% 100%;
								@include mq() {
									background: none;
								}
							}
						}
					}
				}

				.input-select {
					display: flex;
					// resumes
					.datetime_label {
						display: inline-block;
						padding-right: 10px;
						line-height: 44px;
						font-family: $font-notoJp;
						font-size: 1.2rem;
						font-weight: bold;
						color: #4B4A45;
					}

					.item {
						width: 130px;
						border: 2px solid #EBEDED;
						border-radius: 6px;
						font-family: $font-notoJp;
						@include font-size(13);
						font-weight: bold;
						color: #4B4A45;
						margin-right: rem(7);
						@include mq() {
							width: calc((100% - 14px)/3);
							margin-bottom: 0;
						}

						.form-control {
							height: 40px;
							background: white url("/img/recruit/apply/dropdown.png") no-repeat 89%;
						}

						&:last-of-type {
							margin-right: 0;
						}
						// resumes
						&-datetime_select {
							width: 120px;
							padding-right: -20px;

							select {
								width: 40%;
								padding: 0 10px;
							}
						}
					}
				}
				// resumes
				.input-checkbox {
					font-size: 1.3rem;
					display: flex;
					padding: 10px 0;

					.check_box_label {
						padding: 4px 14px;
						background: #eee;
						height: 2.4rem;
						border: none;
						border-radius: 12px;
					}

					input {
						display: none;
						width: 100%;

						&:checked {
							&+label {
								color: #fff;
								background: #FC9D03;
								width: 4.0rem;
								height: 2.4rem;
								border-radius: 12px;
								border: none;
							}
						}
					}

					label {
						padding-left: rem(25);
						cursor: pointer;
						padding-left: rem(15);
						color: #A6ADB4;
						height: 44px;
						align-items: center;
					}
				}
				// resumes
				.form_field {
					.history_number {
						color: #ddd;
					}

					.history_fields {
						.nested-fields {
							width: 100%;

							.item_input {
								.input-history {
									display: flex;
									font-family: $font-notoJp;
									.form-history {
										margin: 4px 4px 4px 0;
										width: 80px;
										height: 40px;
										border: 2px solid #ddd;
										border-radius: 8px;
										background-color: #fff;
									}

									.history_remove {
										display: inline-block;
										text-align: center;
										line-height: 40px;
										border-radius: 5px;
										width: 40px;
										height: 40px;
										background-color: #eee;
										margin: 4px;
										font-weight: bold;
										color: #333;

									}

								}

							}
						}

						.links {
							width: 100%;
							margin: 1rem 0;
							padding: 0 1.5rem;
							display: inline-block;
							text-align: center;
							@include mq() {
								padding: 0;
							}

							.add_fields {
								padding: 5px 30px;
								background-color: #FC9C04;

								display: inline-block;
								max-width: 360px;
								width: 100%;
								border-radius: 5px;
								color: #fff;
								font-weight: bold;
								:hover {
									opacity: 0.8;
									transition: 0.3s;
								}
							}
						}
					}
				}

				.error {
					font-family: $font-notoJp;
					@include font-size(14);
					color: #FF4D4D;
					display: block;

					&-full {
						padding-left: 15px;
						@include mq() {
							padding-left: 0;
						}
					}
				}
			}
		}

		&-single {
			margin-bottom: rem(38);
			@include mq() {
				margin-bottom: 0;
			}
			.item {
				&_label {
					border-radius: 8px 0 0 8px;
				}

				&_input {
					border-radius: 0 8px 8px 0;
				}
			}
		}
	}

	.box-confirm {
		max-width: 472px;
		margin: 0 auto rem(32);
		@include mq() {
			max-width: 343px;
			margin-bottom: rem(17);
		}

		button {
			@include font-size(18);
			font-weight: bold;
			height: 80px;
			transition: 0.3s ease all;
			cursor: pointer;

			&:hover {
				opacity: 0.7;
				transition: 0.3s ease all;
			}
			@include mq() {
				@include font-size(13);
				height: 60px;
			}
		}

		.btn {
			@include font-size(18);
			@include mq() {
				@include font-size(15);
			}
		}
	}

	.policy {
		font-family: $font-notoJp;
		text-align: center;
		color: #FC9C04;
		margin-bottom: rem(24);

		p {
			display: inline-block;
			font-size: 1.8rem;
		}

		@include mq(md-min) {
			margin-bottom: rem(24);
			p {
				display: inline-block;
				font-size: 2rem;
				cursor: pointer;

				&:hover {
					opacity: 0.7;
				}
			}
		}
	}

	.policy-modal {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
	}
		
	.policy-modal-content {
		display: block;
		overflow-y: auto; 
  	width: 95%;
		max-height: 90%; 
  	background-color: #fff;
  	border-radius: 8px;
  	padding: rem(10);
		margin-top: rem(40);
		
		@include mq(md-min) {
			width: 70%;
			max-height: 70%; 
			padding: rem(20);
			margin-top: rem(140);
		}
	}

	.policy-modal-content button {
		font-size: 1.4rem;
		font-weight: bold;
		color: #fff;
		background-color: #FC9C04;
		border: none;
		border-radius: 20px;
		padding: rem(10) rem(20);
		margin-top: rem(5);
		

		@include mq(md-min) {
			font-size: 1.6rem;
			padding: rem(10) rem(20);
			margin-top: rem(10);
			cursor: pointer;
		}
	}
	
	.policy-modal-content button:hover {
		opacity: 0.8;
	}
		
	.policy-modal-content iframe {
		width: 100%;
		height: 450px;
		border: none;

		@include mq(md-min) {
			width: 100%;
			height: 450px;
		}
	}
}

.sec-recruit-complete {
	padding: rem(60) 0 rem(50);
	@include mq() {
		padding: rem(30) 0 0;
	}

	.inner-960 {
		min-height: 40vh;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.relate_box {
			.recruit_heading {
				margin-bottom: rem(20);
			}
		}

		span {
			color: #FF4D4D;
		}

		//resume
		.complete_text {
			margin: 8rem 2rem;
		}
	}
}
