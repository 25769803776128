/* ====================================================
Font
==================================================== */

$font-gothic2:"ヒラギノ丸ゴ Pro W4","ヒラギノ丸ゴ Pro","Hiragino Maru Gothic Pro","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","HG丸ｺﾞｼｯｸM-PRO","HGMaruGothicMPRO";
$font-gothic: "ヒラギノ角ゴPro W3","Hiragino Kaku Gothic Pro", Osaka,"Meiryo","メイリオ","MS Pゴシック",Arial, Helvetica, Verdana,sans-serif;
$font-mincho: YuMincho,"游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", 'Sawarabi Mincho',"Yu Mincho","ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
$font-Yugothic: "游ゴシック体", "Yu Gothic", YuGothic,"ヒラギノ角ゴPro W3","Hiragino Kaku Gothic Pro", Osaka,"Meiryo","メイリオ","MS Pゴシック",Arial, Helvetica, Verdana,sans-serif;

$font-notoJp:'Noto Sans JP',"ヒラギノ角ゴPro W6","Hiragino Kaku Gothic Pro", Osaka,"Meiryo","メイリオ","MS Pゴシック",Arial, Helvetica, Verdana,sans-serif;
$font-Notoserif :'Noto Serif', YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
$font-Roboto :'Roboto', "游ゴシック体", "Yu Gothic", YuGothic,"ヒラギノ角ゴPro W3","Hiragino Kaku Gothic Pro", Osaka,"Meiryo","メイリオ","MS Pゴシック",Arial, Helvetica, Verdana,sans-serif;

@mixin font-size($sizeValue) {
	font-size: ($sizeValue) + px;
	font-size: calc($sizeValue / 10) + rem;
}

@mixin font-vw($sizeValue) {
	font-size: ($sizeValue) + px;
	font-size: ($sizeValue / (750/100)) + vw; /* 750 = design width, mobile only */
}

// convert px to rem
@function rem($element-size) {
	$vwSize: calc($element-size / 10);
	@return #{$vwSize}rem;
}


/* ====================================================
Position & transform
==================================================== */
@mixin v-align {
	top: 50%;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

@mixin h-align {
	left: 50%;
	-webkit-transform: translateX(-50%);
	   -moz-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	     -o-transform: translateX(-50%);
	        transform: translateX(-50%);
}

@mixin v-h-align {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	   -moz-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	     -o-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

@mixin smooth-transition {
	-webkit-backface-visibility: hidden;
	-webkit-transition: all .3s ease;
	   -moz-transition: all .3s ease;
	    -ms-transition: all .3s ease;
	     -o-transition: all .3s ease;
	        transition: all .3s ease;
}



/* ====================================================
Media Quary
==================================================== */
@mixin mq($breakpoint: md) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

$breakpoint-sm: 640px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 990px !default;

$breakpoints: (
		'xs': 'screen and (max-width: 374px)',
		'sm': 'screen and (max-width: 640px)',
		'md': 'screen and (max-width: 768px)',
		'lg': 'screen and (max-width: 990px)',
		'ipd': 'screen and (max-width: 1024px)',
		'xl': 'screen and (max-width: 1200px)',
		'xl-1300': 'screen and (max-width: 1300px)',
		'xl-1500': 'screen and (max-width: 1500px)',
		'sm-min': 'screen and (min-width: 641px)',
		'md-min': 'screen and (min-width: 769px)',
		'lg-min': 'screen and (min-width: 991px)',
		'ipd-min': 'screen and (min-width: 1025px)',
		'xl-min': 'screen and (min-width: 1201px)',
) !default;


$base-size: 16px;
@function px-em($target, $context: $base-size) {
	@if $target == 0 {
		@return 0
	}
	@return $target / $context + 0em;
}

@function calc-vw($size) {
	$vwSize: ($size / 640) * 100;
	@return #{$vwSize}vw;
}

/* ====================================================
Misc
==================================================== */
@mixin no-select {
	 -khtml-user-select: none;
	-webkit-user-select: none;
	   -moz-user-select: none;
	     -o-user-select: none;
	        user-select: none;
}