.recruit-button{
  display: flex;
  .detail-button-wrapper,
  .application-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 49px;

    .button-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding-right: rem(4);
    }

    .button-text {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .arrow-icon {
      position: relative;
      left: 15px;
    }

    .detail-button,
    .application-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      width: 100%;
      height: 100%;
      font-weight: bold;
      text-decoration: none;
      font-size: rem(14);
      border: none;
    }
  }

  .detail-button-wrapper {
    border: 2px solid #fc9c04;
    background-color: #fff;

    .detail-button {
      color: #fc9c04;
    }
  }

  .application-button-wrapper {
    border: none;
    background-color: #fc9c04;

    .application-button {
      color: #fff;
    }
  }
  @include mq(ipd) {
    justify-content: center;
  }
}