@mixin cta-button {
	width: calc(335px + (736 - 335) * ((100vw - 375px) / (1280 - 375)));
	height: calc(46px + (67 - 46) * ((100vw - 375px) / (1280 - 375)));
	background-color: #EB5769;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	padding: calc((18 / 1280) * 100vw);

	span {
		font-size: calc(18px + (24 - 18) * ((100vw - 375px) / (1280 - 375)));
		font-weight: bold;
		color: #FFFFFF;
	}

	@include mq(md-min) {
		width: calc((736 / 1280) * 100vw);
		height: calc((67 / 1280) * 100vw);
		padding: calc((18 / 1280) * 100vw);
		box-shadow: 0 6px 0 #c54251;

		span {
			font-size: calc((24 / 1280) * 100vw);
		}
	}
}

.recruit-page {
  scroll-behavior: smooth;
}
// TOP画面
.recruit-mv {
  max-width: 100%;
  position: relative;
}

.recruit-mv-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.logo-sp {
  display: block;
  position: absolute;
  width: 25%;
  height: auto;
  top: 8%;
  left: 8%;
}

.logo-pc {
  display: none;
}

.fix-btn {
  display: none;
}

@include mq(md-min) {
  .logo-pc {
    display: block;
    position: absolute;
    width: 19%;
    height: auto;
    top: 5%;
    left: 4%;
  }

  .logo-sp {
    display: none;
  }

  .fix-btn{
    display: block;
    position: fixed;
    width: 210px;
    height: 70px;
    top: -16px;
    right: 50px;
    font-size: 2rem;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #EB5769;
    border-radius: 18px;
    text-decoration: none;
    text-align: center;
    border: none;
    box-shadow: 0 5px 0 #c54251;
    padding: rem(28) rem(10) 0 0;
    z-index: 99999;
    cursor: pointer;
  }

  .search-icon {
    position: relative;
    width: 12%;
    left: 3%;
  }
}

// ナビゲーションバー
.recruit-nav {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: calc((64 / 1280) * 100vw);

  li {
    width: calc(50% - rem(24));
    height: 52px;
    color: #FFFFFF;
    background-color: #553E2699;
    text-align: center;
    font-size: calc(15px + (18 - 15) * ((100vw - 375px) / (1280 - 375)));
    font-weight: bold;
    margin-top: rem(8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:nth-child(odd) {
      margin-right: rem(7);
    }
  }

  a {
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    text-decoration: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .recruit-nav-icon {
    width: calc(12px + (15 - 12) * ((100vw - 375px) / (1280 - 375)));
    margin-top: rem(2);
    align-self: center;
  }

  @include mq(md-min) {
    flex-wrap: nowrap;
    margin-top: 0;

    li {
      width: calc((260 / 1280) * 100vw);
      height: calc((64 / 1280) * 100vw);
      color: #FFFFFF;
      background-color: #553E2699;
      font-size: calc((18 / 1280) * 100vw);
      font-weight: bold;
      text-align: center;
      margin-top: calc((48 / 1280) * 100vw);
      margin-right: rem(4);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:nth-child(odd) {
        margin-right: rem(4);
      }
      &:nth-child(4) {
        margin-right: 0;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    a {
      color: #FFFFFF;
      display: flex;
      flex-direction: column;
      justify-content: center; 
      align-items: center;
      text-decoration: none;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    .recruit-nav-icon {
      width: calc((15 / 1280) * 100vw);
      margin-top: rem(4);
    }
  }
}

// 私たちの仕事
.recruit-ourwork {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(24px + (64 - 24) * ((100vw - 375px) / (1280 - 375)));

  .ourwork-title-sp {
    display: block;
    width: 80%;
    height: auto;
  }

  .ourwork-title-pc {
    display: none;
  }

  @include mq(md-min) {
    margin-top: calc((64 / 1280) * 100vw);
    .ourwork-title-pc {
      display: block;
      width: calc((370 / 1280) * 100vw);
      height: auto;
      margin: 0 auto;
    }

    .ourwork-title-sp {
      display: none;
    }
  }
}

.ourwork-content {
  display: flex;
  flex-direction: column;
  
  .ourwork-content-img {
    display: none;
  }

  .ourwork-content-title-sp {
    display: block;
    width: 90%;
    height: auto;
    margin: 0 auto;
  }

  .ourwork-content-title-pc {
    display: none;
  }

  .ourwork-content-title-img-sp {
    display: block;
    width: 65%;
    height: auto;
    margin: 0 auto;
    margin-top: calc(8px + (16 - 8) * ((100vw - 375px) / (1280 - 375)));
  }

  .ourwork-content-text-sp {
    text-align: center;
    margin-top: calc(8px + (16 - 8) * ((100vw - 375px) / (1280 - 375)));
    p {
      font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1280 - 375)));
      color: #58595B;
      padding-top: calc(6px + (8 - 6) * ((100vw - 375px) / (1280 - 375)));
    }

    p.text-space {
      padding-top: calc(24px + (32 - 24) * ((100vw - 375px) / (1280 - 375)));
    }
  }

  @include mq(md-min) {
    flex-direction: row;
    max-width: 82%;
    margin: 0 auto;
    margin-top: calc((64 / 1280) * 100vw);
    
    .ourwork-content-img {
      display: block;
      width: 41%;
      height: auto;
    }

    .ourwork-content-title-pc {
      display: block;
      width: 80%;
      height: auto;
      margin-left: calc((64 / 1280) * 100vw);
    }

    .ourwork-content-title-sp {
      display: none;
    }

    .ourwork-content-title-img-pc {
      display: block;
      width: 45%;
      height: auto;
    }

    .ourwork-content-title-img-sp {
      display: none;
    }

    .ourwork-content-text-pc {
      margin-top: calc((16 / 1280) * 100vw);
      p {
        font-size: calc((17 / 1280) * 100vw);
        color: #58595B;
        padding-top: calc((8 / 1280) * 100vw);

        &:last-child {
          padding-top: calc((24 / 1280) * 100vw);
        }
      }
    }
  }
}

// 未経験、経験者
.experience-img-pc {
  display: none;
  margin-top: calc((96 / 1280) * 100vw);
}

.experience-img-sp {
  display: block;
  margin-top: calc(32px + (96 - 32) * ((100vw - 375px) / (1280 - 375)));
  width: 100%;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

@include mq(md-min) {
  .experience-img-pc {
    display: block;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .experience-img-sp {
    display: none;
  }
}


// インタビュー
.recruit-interview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(48px + (128 - 48) * ((100vw - 375px) / (1280 - 375)));

  .interview-title-sp {
    width: 80%;
    height: auto;
    display: block;
  }

  .interview-title-pc {
    display: none;
  }
  
  // pcサイズ(769px以上)
  @include mq(md-min) {
    margin-top: calc((74 / 1280) * 100vw);
    .interview-title-pc {
      display: block;
      width: calc((370 / 1280) * 100vw);
      height: auto;
      margin: 0 auto;
    }

    .interview-title-sp {
      display: none;
    }
  }
}

.interview-swiper-container {
  position: relative;

  .swiper-pagination {
    position: relative;
  }

  .navigation-pagination-wrapper-sp {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: calc(-40px + (-80 - -40) * ((100vw - 375px) / (1280 - 375)));
    left: 0;
    right: 0;
  }

  .navigation-pagination-wrapper-sp .swiper-pagination-bullet {
    background: #D9D9D9;
    opacity: 1;
  }

  .navigation-pagination-wrapper-sp .swiper-pagination-bullet-active {
    background: #9CC24F;
  }

  .custom-swiper-pagination-sp {
    max-width: 200px;
    text-align: center;
    bottom: -0px !important;
  }

  .swiper-button-prev-sp, .swiper-button-next-sp {
    position: absolute;
    width: calc(12px + (15 - 12) * ((100vw - 375px) / (1280 - 375)));
  }
  
  .swiper-button-prev-sp {
    left: 10vw;
  }
  
  .swiper-button-next-sp {
    right: 10vw;
  }

  .swiper-button-prev-sp {
    display: block;
  }

  .swiper-button-prev-pc {
    display: none;
  }

  .swiper-button-next-sp {
    display: block;
  }

  .swiper-button-next-pc {
    display: none;
  }

  .interview-swiper {
    margin-top: rem(16);
    width: calc(300px + (900 - 300) * ((100vw - 375px) / (1280 - 375)));
    height: auto;
  }

  .interview-swiper-sp {
    display: block;
  }

  .interview-swiper-pc {
    display: none;
  }

  .interview-swiper img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .interview-swiper-overlay p {
    display: none;
  }

  .interview-info {
    align-items: center;
    margin-top: rem(4);
  }

  .interview-catch-copy {
    text-align: center;
    font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1280 - 375)));
    font-weight: bold;
    white-space: pre-wrap;
  }

  .entry-details {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: calc(2px + (8 - 2) * ((100vw - 375px) / (1280 - 375)));
    padding-bottom: rem(6)
  }
  .entry-details::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 85%;
    border-bottom: 1px solid #787878;
  }

  .interview-entry-year {
    font-size: calc(13px + (30 - 13) * ((100vw - 375px) / (1280 - 375)));
    color: #787878;
    font-weight: 300;
    transition: color 0.3s ease;
  }

  .interview-name {
    margin-left: rem(8);
    font-size: calc(13px + (30 - 13) * ((100vw - 375px) / (1280 - 375)));
    font-weight: 400;
    color: #787878;
  }

  @include mq(md-min) {
    margin-top: calc((48 / 1280) * 100vw);

    .swiper-pagination {
      position: relative;
    }

    .navigation-pagination-wrapper-pc {
      display: block;
    }

    .navigation-pagination-wrapper-sp {
      display: none;
    }
  
    .swiper-button-prev-pc, .swiper-button-next-pc {
      display: block;
      position: absolute;
      top: calc((150 / 1280) * 100vw);
      width: calc((15 / 1280) * 100vw);
      cursor: pointer;
    }
  
    .swiper-button-prev-pc {
      left: calc((-50 / 1280) * 100vw);
    }
  
    .swiper-button-next-pc {
      right: calc((-50 / 1280) * 100vw);
    }

    .interview-swiper {
      width: calc((900 / 1280) * 100vw);
      height: auto;
      cursor: pointer;
    }

    .interview-swiper-pc {
      display: block;
    }
    
    .interview-swiper-sp {
      display: none;
    }

    .interview-swiper img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .coming-soon {
      pointer-events: none;
    }

    .interview-swiper-overlay {
      position: relative;
      overflow: hidden;
    }

    .interview-swiper-overlay p {
      display: block;
    }

    .interview-number {
      position: absolute;
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s ease;
      color: #FFFFFF;
      font-size: calc((17 / 1280) * 100vw);
      font-weight: bold;
    }    
    
    .interview-detail {
      position: absolute;
      top: 58%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s ease;
      color: #FFFFFF;
      font-size: calc((17 / 1280) * 100vw);
      font-weight: bold;
    }

    .interview-swiper-overlay:hover .interview-number,
    .interview-swiper-overlay:hover .interview-detail {
      opacity: 1;
    }

    .interview-info {
      display: block;
      text-align: left;
      margin-top: 0;
    }

    .interview-catch-copy {
      text-align: center;
      font-size: calc((15 / 1280) * 100vw);
      font-weight: bold;
      white-space: pre-wrap;
      margin-top: calc((8 / 1280) * 100vw);
    }

    .entry-details {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      margin-top: calc((8 / 1280) * 100vw);
      padding-bottom: calc((8 / 1280) * 100vw);
    }

    .entry-details::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 70%;
      border-bottom: 1px solid #787878;
    }
    
    .interview-entry-year {
      font-size: calc((14 / 1280) * 100vw);
      font-weight: bold;
      color: #787878;
    }

    .interview-name {
      font-size: calc((14 / 1280) * 100vw);
      font-weight: bold;
      color: #787878;
      margin-left: 0;
      padding-left: calc((8 / 1280) * 100vw);
    }
  }
}

// カレッジ
.recruit-college {
  margin-top: calc(96px + (128 - 48) * ((100vw - 375px) / (1280 - 375)));

  .college-title-sp {
    display: block;
    width: 80%;
    height: auto;
    margin: 0 auto;
  }

  .college-title-pc {
    display: none;
  }

  @include mq(md-min) {
    margin-top: calc((64 / 1280) * 100vw);
    .college-title-pc {
      display: block;
      width: calc((432 / 1280) * 100vw);
      height: auto;
      margin: 0 auto;
    }

    .college-title-sp {
      display: none;
    }
  }
}

.college-container {
  position: relative;
  margin-top: calc(16px + (48 - 16) * ((100vw - 375px) / (1280 - 375)));

  // 研修施設について
  .college-content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .college-content-text {
    order: 2;
    width: 90%;
    height: auto;
    margin-left: calc(18px + (48 - 18) * ((100vw - 375px) / (1280 - 375)));
    margin-top: calc(16px + (48 - 16) * ((100vw - 375px) / (1280 - 375)));
  }

  .college-title {
    color: #9CC24F;
    font-size: calc(27px + (67 - 27) * ((100vw - 375px) / (1280 - 375)));
  }

  .college-text-sp {
    margin-top: calc(8px + (16 - 8) * ((100vw - 375px) / (1280 - 375)));
    
    p {
      color: #58595B;
      font-size:  calc(14px + (40 - 14) * ((100vw - 375px) / (1280 - 375)));
      padding-top: calc(8px + (0) * ((100vw - 375px) / (1280 - 375)));
    }
  }

  .college-swiper {
    order: 1;
    width: 90%;
    height: auto;
  }

  .college-swiper img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .college-swiper-sp {
    display: block;
  }

  .college-text-pc, .college-swiper-pc, .swiper-button-prev, .swiper-button-next { 
    display: none;
  }

  // ドットページネーションのカラー
  .swiper-pagination-bullet {
    background: #D9D9D9;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: #9CC24F;
  }

  // カレッジ講師について
  .lecturer-content {
    position: relative;
    display: block; 
    margin: 0 auto;
  }

  .lecture-img-sp {
    display: block;
    width: 90%;
    height: auto;
    margin: 0 auto;
    margin-top: calc(16px + (48 - 16) * ((100vw - 375px) / (1280 - 375)));
  }

  .message-content-text-sp {
    width: calc(275px + (800 - 275) * ((100vw - 375px) / (1280 - 375)));
    height: calc(159px + (234 - 159) * ((100vw - 375px) / (1280 - 375)));
    border: 2px solid #9CC24F;
    border-radius: 12px;
    background-color: #FFFFFF;
    margin: 0 auto;
    transform: translateY(-20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .message-img-sp {
    position: absolute;
    width: 40%;
    height: auto;
    left: 72%;
    bottom: 80%;
    transform: translateX(-50%); 
  }

  .message-img-sp img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .message-text-sp {
    margin: 0 auto;
    max-width: 85%;

    p {
      color: #58595B;
      font-size: calc(13px + (24 - 13) * ((100vw - 375px) / (1280 - 375)));
    }
  }
  
  .message-content-text-pc {
    display: none;
  }

  .lecture-img-sp, .message-img-sp, .message-text-sp {
    display: block;
  }

  .lecture-img-pc, .message-img-pc, .message-text-pc {
    display: none;
  }

  // 全国のカレッジ
  .nationwide-content-sp {
    display: flex;
    padding-bottom: calc(24px + (48 - 24) * ((100vw - 375px) / (1280 - 375)));
  }

  .nationwide-img-sp {
    display: block;
    width: 30%;
    height: auto;
    margin-left: calc(24px + (48 - 24) * ((100vw - 375px) / (1280 - 375)));
  }

  .nationwide-img-sp img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .nationwide-text-sp {
    margin-left: calc(16px + (32 - 16) * ((100vw - 375px) / (1280 - 375)));
    margin-top: calc(6px + (16 - 6) * ((100vw - 375px) / (1280 - 375)));
    h4 {
      font-size: calc(18px + (46 - 18) * ((100vw - 375px) / (1280 - 375)));
      font-weight: bold;
      color: #9CC24F;
      margin-bottom: calc(16px + (48 - 16) * ((100vw - 375px) / (1280 - 375)));
    }
  }

  .nationwide-list-sp {
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      align-items: center;
      margin-bottom: calc(4px + (16 - 4) * ((100vw - 375px) / (1280 - 375)));
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(60px + (75 - 60) * ((100vw - 375px) / (1280 - 375)));
      height: calc(20px + (40 - 20) * ((100vw - 375px) / (1280 - 375)));
      background-color: #9CC24F;
      text-align: center;
      color: #FFFFFF;
      font-size: calc(13px + (24 - 13) * ((100vw - 375px) / (1280 - 375)));
      font-weight: bold;
      margin-right: calc(8px + (16 - 8) * ((100vw - 375px) / (1280 - 375)));
    }

    p {
      font-size: calc(13px + (24 - 13) * ((100vw - 375px) / (1280 - 375)));
      color: #787878;
    }
  }

  // カレッジプロセス
  .process-content-sp {
    display: block;
    flex-direction: column;
    align-items: center;
  }

  .process-content-sp h3 {
    font-size: calc(27px + (67 - 27) * ((100vw - 375px) / (1280 - 375)));
    font-weight: 500;
    color: #9CC24F;
    margin-left: calc(19px + (64 - 20) * ((100vw - 375px) / (1280 - 375)));
    margin-right: calc(19px + (64 - 20) * ((100vw - 375px) / (1280 - 375)));
    border-top: 1px solid #9CC24F;
    padding-top: calc(24px + (64 - 24) * ((100vw - 375px) / (1280 - 375)));
  }

  .process-content-sp-img {
    display: block;
    width: 90%;
    height: auto;
    margin: 0 auto;
  }
  .process-img-sp {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .process-content-title-sp {
    border: 1px solid #9CC24F;
  }

  .process-content-pc, .process-content-pc-img {
    display: none;
  }

  .process-content-sp {
    display: block;
  }

  @include mq(md-min) {
    margin-top: calc((48 / 1280) * 100vw);

    .college-content {
      display: flex;
      flex-direction: row;
      max-width: 80%;
      margin: 0 auto;
    }

    .college-swiper,
    .college-content-text {
      order: 0;
    }

    .college-content-text {
      width: calc((480 / 1280) * 100vw);
      height: auto;
      margin-left: 0;
      margin-top: 0;
    }

    .college-title {
      color: #9CC24F;
      font-size: calc((41 / 1280) * 100vw);
    }

    .college-text-pc {
      display: block;
      margin-top: calc((8 / 1280) * 100vw);
      
      p {
        color: #58595B;
        font-size: calc((17 / 1280) * 100vw);
        padding-top: calc((8 / 1280) * 100vw);;
      }
    }

    .college-text-sp {
      display: none;
    }

    .college-swiper {
      width: 42%;
      height: auto;
    }

    .college-swiper img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .college-swiper-pc {
      display: block;
    }

    .college-swiper-sp {
      display: none;
    }
  
    .swiper-button-prev, .swiper-button-next {
      display: block;
      position: absolute;
      top: calc((150 / 1280) * 100vw);
      width: calc((15 / 1280) * 100vw);
      cursor: pointer;
    }
  
    .swiper-button-prev {
      left: 48%;
    }
  
    .swiper-button-next {
      right: 10.5%;
    }

    // カレッジ講師
    .lecturer-content {
      display: block;
      display: flex;
      max-width: 80%;
      margin: 0 auto;
    }

    .lecture-img-pc {
      display: block;
      width: 47%;
      height: auto;
      margin-right: calc((50 / 1280) * 100vw);
      margin-top: calc((-25 / 1280) * 100vw);
    }

    .lecture-img-pc img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .lecture-img-sp {
      display: none;
    }

    // 講師メッセージ
    .message-content-text-pc {
      display: block;
      width: calc((470 / 1280) * 100vw);
      height: calc((200 / 1280) * 100vw);
      border: 2px solid #9CC24F;
      border-radius: 12px;
      margin-top: calc((48 / 1280) * 100vw);
    }

    .message-img-pc {
      display: block;
      position: relative;
      width: 35%;
      height: auto;
      right: 9%;
      bottom: 25%;
    }

    .message-img-pc img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .message-text-pc {
      display: block;
      position: relative;
      left: 5%;
      bottom: 35%;
      max-width: 90%;
      
      p {
        color: #58595B;
        font-size: calc((17 / 1280) * 100vw);
        padding-top: calc((8 / 1280) * 100vw);
      }
    }

    .message-img-pc, .message-text-pc {
      display: block;
    }
  
    .lecture-img-sp, .message-img-sp, .message-text-sp, .message-content-text-sp {
      display: none;
    }

    // 全国のカレッジ
    .nationwide-content-pc {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nationwide-img-pc {
      display: block;
      position: relative;
      width: 20%;
      height: auto;
      right: 5%;
    }

    .nationwide-img-pc img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .nationwide-img-sp {
      display: none;
    }

    .nationwide-text-pc {
      margin-left: calc((-20 / 1280) * 100vw);
      h4 {
        font-size: calc((23 / 1280) * 100vw);
        font-weight: bold;
        color: #9CC24F;
      }
    }

    .nationwide-list-pc {
      li {
        display: flex;
        align-items: center;
        margin-left: calc((32 / 1280) * 100vw);
        margin-bottom: calc((6 / 1280) * 100vw);
        height: calc((20 / 1280) * 100vw);
      }
      
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc((45 / 1280) * 100vw);
        height: calc((20 / 1280) * 100vw);
        background-color: #9CC24F;
        text-align: center;
        color: #FFFFFF;
        font-size: calc((13 / 1280) * 100vw);
        font-weight: bold;
        margin-right: calc((8 / 1280) * 100vw);
      }
      
      p {
        font-size: calc((18 / 1280) * 100vw);
        color: #787878;
        line-height: calc((20 / 1280) * 100vw);
      }
    }

    .nationwide-content-sp, .nationwide-img-sp, .nationwide-text-sp, .nationwide-list-sp {
      display: none;
    }

    // カレッジプロセス
    .process-content-pc {
      display: flex;
      flex-direction: column;
    }
  
    .process-content-title-pc {
      display: flex;
    }
  
    .process-content-title-pc {
      h3 {
        font-size: calc((37 / 1280) * 100vw);
        font-weight: 400;
        color: #9CC24F;
        margin-bottom: calc((16 / 1280) * 100vw);
        margin-left: calc((128 / 1280) * 100vw);
      }

      p {
        display: flex;
        background-color: #9CC24F;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: calc((20 / 1280) * 100vw);
        font-weight: bold;
        width: calc((120 / 1280) * 100vw);
        height: calc((30 / 1280) * 100vw);
        margin-left: calc((32 / 1280) * 100vw);
        margin-top: calc((10 / 1280) * 100vw);
      }
    }
    
    .process-content-pc-img {
      display: flex;
      justify-content: center;
    }
    
    .process-img-pc {
      width: 80%;
      height: auto;
    }
  
    .process-img-pc img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  
    .process-content-sp {
      display: none;
    }
  }

  .college-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: calc(24px + (48 - 24) * ((100vw - 375px) / (1280 - 375)));

    .college-title {
      width: 75%;
      height: auto;
      margin-bottom: calc(16px + (48 - 16) * ((100vw - 375px) / (1280 - 375)));
    }

    .college-link {
      @include cta-button;
    }

    .college-link-img {
      position: relative;
      width: calc(25px + (35 - 25) * ((100vw - 375px) / (1280 - 375)));
      height: auto;
      right: calc(6px + (8 - 6) * ((100vw - 375px) / (1280 - 375)));
    }
  }
  @include mq(md-min) {
    .college-button {
      margin-top: calc((168 / 1280) * 100vw);
      margin-bottom: calc((96 / 1280) * 100vw);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
      .college-title {
        width: 25%;
        height: auto;
        margin-bottom: calc((16 / 1280) * 100vw);
      }

      .college-link {
        @include cta-button;
      }

      .college-link-img {
        position: relative;
        width: calc((35 / 1280) * 100vw);
        height: auto;
        top: calc((2 / 1280) * 100vw);
        right: calc(8 / 1280 * 100vw);
      }
    }
  }
}

// 仕事内容
.recruit-ourjob {
  display: block;
  height: auto;
  margin-top: calc(64px + (96 - 64) * ((100vw - 375px) / (1280 - 375)));

  .ourjob-title-sp {
    width: 80%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .ourjob-title-pc {
    display: none;
  }

  @include mq(md-min) {
    margin-top: calc((96 / 1280) * 100vw);
    .ourjob-title-pc {
      display: block;
      width: calc((370/ 1280) * 100vw);
      height: auto;
      margin: 0 auto;
    }

    .ourjob-title-sp {
      display: none;
    }
  }

  .ourjob-container {
    margin-top: calc(16px + (48 - 16) * ((100vw - 375px) / (1280 - 375)));

    .ourjob-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .ourjob-content-1 {
      display: flex;
      margin-bottom: calc((6 / 1280) * 100vw);
    }

    .ourjob-content-2 {
      display: flex;
      margin-top: calc((24 / 1280) * 100vw);
    }

    .ourjob-treatment,
    .ourjob-communication,
    .ourjob-studio,
    .ourjob-interview {
      position: relative;
      width: calc(102px + (350 - 102) * ((100vw - 375px) / (1280 - 375)));
      height: calc(102px + (350 - 102) * ((100vw - 375px) / (1280 - 375)));
      margin-right: calc(16px + (48 - 16) * ((100vw - 375px) / (1280 - 375)));
    }

    .ourjob-meeting,
    .ourjob-flyer {
      position: relative;
      width: calc(102px + (350 - 102) * ((100vw - 375px) / (1280 - 375)));
      height: calc(102px + (350 - 102) * ((100vw - 375px) / (1280 - 375)));
    }   

    .ourjob-section-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .ourjob-content-details-sp {
      display: flex;
      flex-direction: column;
      width: 90%;
      height: auto;
      margin: 0 auto;
      margin-top: calc(16px + (48 - 16) * ((100vw - 375px) / (1280 - 375)));

      p {
        text-align: left;
        font-size: calc(14px + (24 - 14) * ((100vw - 375px) / (1280 - 375)));
        color: #58595B;
      }
    }
    
    .ourjob-content-details-sp img {
      width: 100%;
      height: auto;
      object-fit: cover;
      margin-bottom: calc(16px + (48 - 16) * ((100vw - 375px) / (1280 - 375)));
    }
    
    .ourjob-content-details-pc {
      display: none;
    }

    @include mq(md-min) {
      display: flex;

      .ourjob-content {
        margin-left: calc((125 / 1280) * 100vw);
        margin-right: calc((52 / 1280) * 100vw);
      }

      .ourjob-content-1 {
        display: flex;
        margin: 0;
      }

      .ourjob-content-2 {
        display: flex;
        margin-top: calc((24 / 1280) * 100vw);
      }

      .ourjob-treatment,
      .ourjob-communication,
      .ourjob-studio,
      .ourjob-interview {
        position: relative;
        width: calc((165 / 1280) * 100vw);
        height: calc((165 / 1280) * 100vw);
        margin-right: calc((24 / 1280) * 100vw);
        cursor: pointer;
      }

      .ourjob-meeting,
      .ourjob-flyer {
        position: relative;
        width: calc((165 / 1280) * 100vw);
        height: calc((165 / 1280) * 100vw);
        cursor: pointer;
      }

      .ourjob-section-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .ourjob-content-details-pc {
        display: flex;
        flex-direction: column;
        width: calc((450 / 1280) * 100vw);
        height: auto;
        margin-right: calc((48 / 1280) * 100vw);

        p {
          font-size: calc((14 / 1280) * 100vw);
          color: #58595B;
        }
      }
      
      .ourjob-content-details-pc img {
        width: 100%;
        height: auto;
        object-fit: cover;
        margin-bottom: calc((16 / 1280) * 100vw);
      }

      .ourjob-content-details-sp {
        display: none;
      }
    }
  }

  .ourjob-button {
    margin-top: calc((96 / 1280) * 100vw);
    margin-bottom: calc((96 / 1280) * 100vw);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .ourjob-title {
      width: 85%;
      height: auto;
      margin-bottom: calc(16px + (48 - 16) * ((100vw - 375px) / (1280 - 375)));
    }

    .ourjob-link {
      @include cta-button;
    }

    .ourjob-link-img {
      position: relative;
      width: calc(25px + (35 - 25) * ((100vw - 375px) / (1280 - 375)));
      height: auto;
      right: calc(4px + (8 - 4) * ((100vw - 375px) / (1280 - 375)));
    }
  }
  @include mq(md-min) {
    .ourjob-button{
      margin-top: calc((80 / 1280) * 100vw);
      margin-bottom: calc((96 / 1280) * 100vw);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
      .ourjob-title {
        width: 35%;
        height: auto;
        margin-bottom: calc((16 / 1280) * 100vw);
      }

      .ourjob-link {
        @include cta-button;
      }

      .ourjob-link-img {
        position: relative;
        width: calc((35 / 1280) * 100vw);
        height: auto;
        top: calc((-2 / 1280) * 100vw);
        right: calc(4 / 1280 * 100vw);
      }
    }
  }
}

// Data
.recruit-data {
  .data-title {
    width: 60%;
    height: auto;
    margin: 0 auto;
    margin-top: rem(48);
    margin-bottom: rem(24);
  }

  p {
    width: 96%;
    text-align: right;
    font-size: rem(12);
    color: #787878;
    margin-top: rem(12);
  }

  @include mq(md-min) {
    .data-title {
      width: 26%;
      height: auto;
      margin: 0 auto;
      margin-bottom: rem(56);
    }

    p {
      width: 93%;
      text-align: right;
      font-size: rem(16);
      color: #787878;
      margin-top: rem(16);
    }
  }
}
.data-container {
  margin: 0 auto;
  width: 90%;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: rem(18);

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mq(md-min) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    width: 84%;
    position: relative;
    left: 10px; 

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      margin: 0;
    }
  }
}

// FAQ
.recruit-faq {
  display: block;
  height: auto;
  margin-top: calc(56px + (96 - 56) * ((100vw - 375px) / (1280 - 375)));

  .faq-title-sp {
    width: 80%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .faq-title-pc {
    display: none;
  }

  @include mq(md-min) {
    margin-top: calc((96 / 1280) * 100vw);
    .faq-title-pc {
      display: block;
      width: calc((150 / 1280) * 100vw);
      height: auto;
      margin: 0 auto;
    }

    .faq-title-sp {
      display: none;
    }
  }
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(32px + (64 - 32) * ((100vw - 375px) / (1280 - 375)));
  
  .faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    width: calc(335px + (1050 - 335) * ((100vw - 375px) / (1280 - 375)));
    border: none; 
    border-top: 1px solid #787878;
    font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1280 - 375)));
    color: #787878;
    background: none;
    padding: calc(18px + (25 - 18) * ((100vw - 375px) / (1280 - 375)));
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    img {
      width: calc(15px + (25 - 15) * ((100vw - 375px) / (1280 - 375)));
      height: calc(15px + (25 - 15) * ((100vw - 375px) / (1280 - 375)));
    }
  }

  .faq-question-open {
    font-weight: bold;
    background-color: #FFE8BB;
    color: #787878;
  }
  
  .faq-answer {
    display: flex;
    align-items: center;
    width: calc(335px + (1050 - 335) * ((100vw - 375px) / (1280 - 375)));
    font-size: calc(12px + (17 - 12) * ((100vw - 375px) / (1280 - 375)));
    color: #787878;
    padding: calc(18px + (25 - 18) * ((100vw - 375px) / (1280 - 375)));
    text-align: left;
  }

  .faq-item-last .faq-question {
    border-bottom: 1px solid #787878; 
  }

  .faq-item-last .faq-answer {
    border-bottom: 1px solid #787878; 
    border-top: none;
  }

  .faq-item-last .faq-question-open {
    border-bottom: none;
  }

  .faq-more {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: calc(273px + (293 - 273) * ((100vw - 375px) / (1280 - 375)));
    height: calc(45px + (51 - 45) * ((100vw - 375px) / (1280 - 375)));
    margin: 0 auto;
    margin-top: calc(32px + (86 - 32) * ((100vw - 375px) / (1280 - 375)));
    border: 1px solid #787878;
    border-radius: 50px;
    background-color: #FFFFFF;
    cursor: pointer;

    span {
      font-size: calc(15px + (17 - 15) * ((100vw - 375px) / (1280 - 375)));
      font-weight: bold;
      color: #787878;
      text-decoration: none;
    }

    img {
      position: absolute;
      width: calc(13px + (15 - 13) * ((100vw - 375px) / (1280 - 375)));
      height: auto;
      right: calc(16px + (24 - 16) * ((100vw - 375px) / (1280 - 375)));
    }
  }

  @include mq(md-min) {
    margin-top: calc((64 / 1280) * 100vw);
    .faq-question {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc((1050 / 1280) * 100vw);
      border: none;
      border-top: 1px solid #787878;
      font-size: calc((20 / 1280) * 100vw);
      color: #787878;
      background: none;
      padding: calc((25 / 1280) * 100vw);
      padding-left: calc((48 / 1280) * 100vw);
      cursor: pointer;
      
      &:hover {
        opacity: 0.7;
      }

      img {
        width: calc((45 / 1280) * 100vw);
        height: calc((45 / 1280) * 100vw);
        padding-right: calc((24 / 1280) * 100vw);
      }
    }

    .faq-question-open {
      font-weight: bold;
      background-color: #FFE8BB;
      color: #787878;
    }

    .faq-answer {
      display: flex;
      align-items: center;
      width: calc((1050 / 1280) * 100vw);
      font-size: calc((17 / 1280) * 100vw);
      color: #787878;
      padding: calc((35 / 1280) * 100vw);
    }

    .faq-item-last .faq-question {
      border-bottom: 1px solid #787878; 
    }

    .faq-item-last .faq-answer {
      border-bottom: 1px solid #787878; 
      border-top: none;
    }

    .faq-item-last .faq-question-open {
      border-bottom: none;
    }

    .faq-more {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: calc((293 / 1280) * 100vw);
      height: calc((51 / 1280) * 100vw);
      margin: 0 auto;
      margin-top: calc((86 / 1280) * 100vw);
      border: 1px solid #787878;
      border-radius: 50px;
      background-color: #FFFFFF;
      cursor: pointer;
    
      &:hover {
        opacity: 0.7;
        background-color: #FFE8BB;
      }

      span {
        font-size: calc((17 / 1280) * 100vw);
        font-weight: bold;
        color: #787878;
        text-decoration: none;
      }

      img {
        position: absolute;
        width: calc((15 / 1280) * 100vw);
        height: auto;
        right: calc((24 / 1280) * 100vw);
      }
    }
  }
}

// フッターリンク
.recruit-footer-pc {
  display: none;
}

@include mq(md-min) {
  .recruit-footer-pc {
    margin: calc((128 / 1280) * 100vw) rem(0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    .footer-title {
      width: 12%;
      height: auto;
      margin-bottom: calc((16 / 1280) * 100vw);
    }

    .footer-link-pc {
      width: calc((736 / 1280) * 100vw);
      height: calc((67 / 1280) * 100vw);
      background-color: #EB5769;
      box-shadow: 0 8px 0 #c54251;
      border-radius: 50px;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      padding: calc((18 / 1280) * 100vw);

      span {
        font-size: calc((24 / 1280) * 100vw);
        font-weight: bold;
        color: #FFFFFF;
      }
    }

    .link-img-pc {
      position: relative;
      width: calc((35 / 1280) * 100vw);
      height: auto;
      top: calc((-4 / 1280) * 100vw);
      left: calc(4 / 1280 * 100vw);
    }
  }
}

.recruit-footer-sp {
  position: fixed;
  width: 100%;
  height: calc(73px + (93 - 73) * ((100vw - 375px) / (1280 - 375)));
  bottom: 0;
  left: 0;
  background-color: rgba(235, 87, 105, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;

  .footer-link-sp {
    width: calc(265px + (726 - 265) * ((100vw - 375px) / (1280 - 375)));
    height: calc(46px + (67 - 46) * ((100vw - 375px) / (1280 - 375)));
    background-color: #EB5769;
    border: 2px solid #FFFFFF;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    span {
      font-size: calc(18px + (24 - 18) * ((100vw - 375px) / (1280 - 375)));
      font-weight: bold;
      color: #FFFFFF;
    }
  }

  .link-img-sp {
    position: relative;
    width: calc(25px + (35 - 25) * ((100vw - 375px) / (1280 - 375)));
    height: auto;
  }

  @include mq(md-min) {
    display: none;
  }
}