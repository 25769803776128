$a: rem(20);
$b: rem(30);
$c:  rem(7.5);
.sec{
	&-message{
		background: rgba(252, 156, 4, 0.1);
		display: inline-block;
		width: 100%;
		padding: rem(60) 0 rem(12);
		@include mq{
			padding:  rem(43) 0 rem(55);
		}
		.ttlCom01{
			margin-bottom:  rem(67);
			@include mq{
				margin-bottom:  rem(41);
			}
		}
		.ttl-content{
			@include mq{
				margin-top:  rem(8);
			}
		}
		.ttl-small{
			@include font-size(14);
			font-family:$font-notoJp;
			font-weight: bold;
			line-height: calc(20/14) ;
			margin-bottom: rem(12);
			@include mq{
				line-height: calc(18/14) ;
			}
		}
		.ttl-large{
			@include font-size(20);
			font-family:$font-notoJp;
			font-weight: bold;
			line-height: calc(23/20) ;
			margin-bottom: rem(15);
			display: inline-block;
			@include mq{
				line-height: calc(23/20) ;
				margin-bottom:rem(13) ;
			}
			span{
				@include font-size(13);
				font-family:$font-notoJp;
				font-weight: 400;
				color: #A6ADB4;
				line-height: calc(23/13) ;
				@include mq{
					line-height: calc(23/13) ;
				}
			}
		}
		.list-message{
			.item{
				display: inline-flex;
				align-items: flex-start;
				justify-content: center;
				width: calc(50% - #{$a});
				margin-right: rem(40);
				margin-bottom: rem(56);
				float: left;
				&:last-child{
					@include mq{
						margin-top: rem(7);
					}
				}
				&:first-child{
					.message-content {
						.ttl-content{
							padding-top: 0;
						}
					}
					.message-img{
						top:0;
					}

				}
				@include mq{
					display: block;
					width: 100%;
					margin-right: 0;
					position: relative;
					margin-bottom: rem(19);
				}
				&:nth-child(2n){
					margin-right: 0;
				}
				.message-img{
					margin-top: rem(37);
					width: 120px;
					@include mq{
						width: 90px;
						position: absolute;
						top:10px;
						left: 0;
						margin-top: 0;

					}
					@include mq(xs){
						width: 70px;
					}
					&.message-img-mt01{
						margin-top: rem(16);
						@include mq{
							margin-top: 0;
						}
					}
				}
				.message-content{
					width: calc(100% - 142px);
					margin-left: rem(22);
					display: flex;
					align-items: center;
					justify-content: center;
					@include mq{
						width: 100%;
						margin-right: 0;
						margin-left: 0;
					}
					& .ttl-small, & .ttl-large{
						@include mq{
							padding-left: rem(100);
						}
						@include mq(xs){
							padding-left: rem(80);
						}

					}
					.ttl-content{
						@include mq{
							padding-top: rem(40);
						}
						@include mq(xs){
							padding-top: rem(20);
						}
					}
				}
				.pc-xl{
					@include mq(xl-1300){
						display: none;
					}
				}

			}
		}
	}
}
.sec{
	&-brand{
		margin: rem(50) 0 rem(28);
		padding: rem(75) 0 rem(120);
		@include mq{
			margin: 0;
			padding: rem(46) 0 rem(27);
			display: inline-block;
		}
		.brand-content{
			margin-top: rem(21);
			@include mq{
				margin-top: rem(26);
			}
		}
		.list-brand{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			@include mq{
				display: block;
			}
			.item{
				display: inline-block;
				width: calc( (100% / 4) - #{$b});
				margin-right: rem(40);
				@include mq{
					margin-bottom: rem(32) ;
				}
				&:last-child{
					margin-right: 0;
				}
				&:nth-child(2n){
					@include mq{
						margin-right: 0;
					}
				}
				@include mq{
					width: calc( (100% / 2) - #{$c});
					margin-right: rem(15);
					float: left;

				}
				.des-brand{
					margin-top: rem(23);
					@include mq{
						margin-top: rem(3);
					}
				}
				.img-brand{
					@include mq{
						text-align: center;
					}
				}
			}
		}

		.txt-style-12{
			@include font-size(12);
			line-height: calc(18/12);
			font-weight: 400;
			@include mq(sm){
				br.sp{
					display: none;
				}
			}
		}
	}
}
.list-item-page{
	&-modify{
		@include mq{
			margin-bottom: 2.5em;
			.item{
				&:last-child{
					.txt-des{
						margin-bottom: 0;
					}
				}
			}
		}


	}
	.txt-indent{
		@include mq(xl-min){
			text-indent: -8px;
			display: inline-block;
		}
	}
}
.list-item-page-modify{
	&.list-item-page-style02 .item:nth-of-type(even){
		@include mq(md-min){
			margin-bottom: 16rem;
		}

	}
}
