.side-bar{
	width: calc((300/1160)*100%);
	//display: inline-block;
	//float:left;
	.list-item{
		margin-top: rem(-3);
		.item{

			a{
				@include font-size(20);
				font-weight: bold;
				line-height: calc(40/20);
				color: #202124;
				cursor: pointer;
			}
			&.active{
				a{
					color: #FC9C04;
				}
			}
		}
	}
	&.fixed {
		.list-wrap-faq {
			position: relative;
			.list-item {
				position: fixed;
			}
		}
	}
}
.sec-main-content
{
	margin-bottom: rem(80);
	@include mq{
		margin-bottom: rem(50);
	}
	&.sec-faq {
		.inner {
			.faq_inner {
				position: relative;
				display: flex;
				flex-wrap: wrap;
			}
			.side-bar-faq {
				&.fixed {
					position: absolute;
					&+div {
						margin-left: auto;
					}
				}
			}
		}
	}
}

.part-content{
	width: calc((860/1160)*100%);
	margin-left: auto;
	//display: inline-block;
	&-com{
		margin-top: rem(40);
		@include mq{
			margin-top: rem(34);
		}
		&:first-child{
			margin-top: 0;
			@include mq{
				margin-top: 0;
			}
		}

	}
	&_list{
		.item{
			&:first-child{
				padding: rem(31) 0 rem(18);
				@include mq{
					padding: rem(31) 0 rem(9);
				}
			}
			&.item-sub{
				padding:rem(18) 0 rem(23);
				@include mq{
					padding: rem(21) 0 rem(9);
				}
				@include mq(md){
					@include mq(sm-min){
						padding: rem(21) 0 rem(9);
					}

				}
			}

			padding: rem(18) 0 rem(18);
			border-bottom: 1px solid #EBEDED;
			@include mq{
				padding: rem(21) 0 rem(9);
			}
			@include mq(md){
				@include mq(sm-min){
					padding: rem(21) 0 rem(9);
				}

			}

		}
	}
	.list-point{
		.item-point{
			margin-bottom: rem(22);
			&:last-child{
				margin-bottom: 0;
			}
			span{
				display: inline-block;
				width: 100%;
				&.txt-note{
					margin-top: rem(25);
				};
			}
		}
	}
	@include mq{
		width: 100%;
	}
	.txtfirst-com{
		font-family: $font-notoJp;
		@include font-size(32);
		font-weight: bold;
		line-height: calc(43/32);
		margin-right: rem(15);
		@include mq{
			@include font-size(24);
			line-height: calc(32/24);
			margin-right: rem(10);
		}
	}

	.item-style-com{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		@include mq{
			align-items: flex-start;
		}
	}
	.item-question{
		&.toggle{
			@include mq{
				padding-right: rem(22);
				margin-bottom: rem(8);
				position: relative;
				&:after{
					content:'';
					width: 19px;
					height: 10px;
					background: url("/img/faq/icon-down.png") no-repeat center center;
					-webkit-background-size: contain;
					background-size: contain;
					position: absolute;
					top:calc(50% - 6.5px);
					right: -6.5px;
					transform: translateX(-50%);

				}
				&.on{
					margin-bottom: rem(0);
					&:after{
						background: url("/img/faq/icon-up.png") no-repeat center center;
						top:calc(50% - 13px);
					}
				}
			}

		}
		&_txtfirst{
			color: #FC9C04;
			@include mq{
				line-height: 1;
			}
		}
		&_content{
			font-family: $font-notoJp;
			@include font-size(16);
			font-weight: bold;
			line-height: calc(20/16);
			@include mq{
				@include font-size(16);
				line-height: calc(24/16);
			}
		}
	}
	.item-awnser{
		margin-top: rem(20);
		@include mq{
			margin-top: rem(12);
		}
		&>div{
			align-items: flex-start;
			display: flex;
			justify-content: flex-start;
		}

		margin-left: rem(2);
		&.toggle-content {
			@include mq{
				display: none;
				padding-right: rem(25);
			}
		}
		&_txtfirst{
			color: #9CC34F;
		}
		&_content{
			font-family: $font-notoJp;
			@include font-size(14);
			font-weight: 400;
			line-height: calc(20/14);
			margin-left: rem(2);
      margin-top: rem(14);
      @include mq{
        margin-top: rem(5);
      }
		}
	}
	.part-content_block02{
		.ttlCom04{
			@include mq{

			}
		}
		.part-content_list{
			.item{
				&:first-child{
					padding: rem(31) 0 rem(7);
				}
			}
		}
	}
}

.part-content_block01 .part-content_list .item .faq-list {
  padding-left: rem(12);
}

.part-content_block01 .part-content_list .item .faq-list li {
	margin-bottom: rem(8);
	list-style-type: disc;
}

.part-content_block02 .part-content_list .item .faq-list {
  padding-left: rem(12);
}

.part-content_block02 .part-content_list .item .faq-list li {
	margin-bottom: rem(8);
	list-style-type: disc;
}