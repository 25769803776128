.sec-recruit-detail {
	margin-bottom: rem(59);
}
.slider_block {
	@include mq {
		margin: 0 rem(-15);
	}
}
.slider-detail {
	margin-bottom: rem(9);
	position: relative;
	.item {
		padding: 0 rem(12);
		img {
			width: 100%;
			height: auto;
		}
		@include mq {
			padding: 0;
		}
	}
	.slick-arrow{
		width: 31px;
		height: 31px;
		background-size: 100%;
		background-repeat: no-repeat;
		z-index: 1;
		top: 50%;
		opacity: 50%;
		&.slick-prev{
			background-image: url("/img/recruit/detail/prev.png");
			left: 7.3%;
			@include mq(ipd){
				left: 5%;
			}
			@include mq(){
				left: 0;
			}
		}
		&.slick-next{
			background-image: url("/img/recruit/detail/next.png");
			right: 7.4%;
			@include mq(ipd){
				right: 5%;
			}
			@include mq(){
				right: 0;
			}
		}
		&:before{
			display: none;
		}
	}
}
.link_back {
	display: block;
	background: #EBEDED;
	padding: rem(10) rem(15);
	@include font-size(11);
	font-weight: bold;
	color: #A6ADB4;
}

.slider-thumb {
	margin-bottom: rem(32);
	@include mq {
		margin-bottom: rem(8);
		padding: 0 rem(13);
	}
  .slick-slide {
    width: 90px !important;
    margin-right: rem(8);
    outline: none;
    @include mq {
      width: 19.9% !important;
      margin-right: initial;
    }
    .item {
      @include mq {
        margin-right: 0 !important;
      }
      span {
        display: block;
        overflow: hidden;
        border-radius: 2px;
        img {
          display: block;
          width: 100%;
					height: auto;
          border: 2px solid transparent;
          @include mq {
            border: 1px solid transparent;
          }
        }
      }
    }
    &.slick-current {
      .item {
        span {
          img {
            border: 2px solid #707070;
            @include mq {
              border: 1px solid #707070;
            }
          }
        }
      }
    }
  }
  .slick-track {
		margin-left: 0;
		transform: none !important;
		width: 100% !important;
		display: flex;
		flex-wrap: wrap;
		.slick-cloned {
			display: none;
		}
	}
}

.job_posting_campaign_banner_top {
	a img {
		margin-bottom: 20px;
		width: 100%;
		height: auto;
	}
	@include mq {
		display: inline;
		a img {
			margin-bottom: 10px;
			width: 100%;
			height: auto;
		}
	}
}


.job_posting_campaign_banner {
	text-align: center;
	a img {
		margin-bottom: 20px;
		width: 50%;
		height: auto;
	}
	@include mq {
		display: inline;
		a img {
			margin: 0;
			width: 100%;
			height: auto;
		}
	}
}

.recruit {
  &_heading {
		@include mq {
			margin-top: rem(51);
			margin-bottom: rem(17);
		}
	}
  &_tabs {
    display: flex;
    justify-content: space-evenly;
  }
	&_name {
		&-second {
			@include font-size(16);
			line-height: 1.25;
			margin-bottom: rem(19);
			@include mq {
				@include font-size(11);
				line-height: 1.667;
				margin-bottom: rem(6);
			}
		}
	}
	&_des {
		&-second {
			@include font-size(24);
			line-height: 1.42;
			font-weight: bold;
			margin-bottom: rem(27);
			@include mq {
				@include font-size(16);
				line-height: 1.25;
				margin: rem(12) 0;
			}
		}
	}
	&_info {
		&_closed-detail{
			color: red;
			margin-bottom: 2.7rem;
			font-size: 24px;
			@include mq {
					margin-top: 3.7rem;
					font-size:16px;
			}
		}
		&-second {
			margin-bottom: rem(12);
			@include mq {
				margin-bottom: rem(9);
			}
			@include mq(md-min) {
				border: 1px solid #A6ADB4;
				border-radius: 8px;
				line-height: 1.714;
			}
			.recruit_field {
				margin: 0 !important;
				border-bottom: 1px solid #A6ADB4;
				@include mq {
					border-bottom: none;
				}
				&:last-child {
					border-bottom: none;
				}
				&-second {
					dd {
						p {
							@include mq(md-min) {
								margin: 0 0 rem(5) 0;
							}
							&:last-child {
								margin-bottom: 0;
							}
						}
            p.salary_text {
              margin-top: 1rem;
            }
						.recruit_tag {
							margin-right: rem(10);
						}
					}
				}
			}
			dt {
				@include mq(md-min) {
					width: 170px;
					border-right: 1px solid #A6ADB4;
					margin: 0;
					padding: rem(15) rem(15);
				}
			}
			dd {
				@include mq(md-min) {
					width: calc(100% - 170px);
					margin: 0;
					padding: rem(15) rem(24) rem(14);
					display: block;
				}
				p {
					@include mq {
						width: 100%;
						margin-right: 0;
					}
				}
			}
			.btn {
				max-width: 365px;
				margin: rem(10) 0 rem(17) rem(4);
				box-shadow: none;
				@include mq {
					height: 44px;
				}
			}
		}
	}
	&_contact {
		&-second {
			justify-content: center;
			margin-bottom: rem(36);
			@include mq {
				flex-direction: column-reverse;
				padding: 0;
				margin-bottom: rem(18);
			}
			.btn {
				width: 100%;
				max-width: 472px;
				margin: 0 rem(8);
				height: 80px;
				@include font-size(18);
				@include mq {
					width: 100%;
					margin: 0;
					height: 60px;
					margin-bottom: rem(8);
					@include font-size(15);
				}
			}
		}
	}

	&_detail_tags {
		margin-bottom: rem(20);
		.tag {
			display: inline-block;
			border: 1px solid #FC9C04;
			border-radius: 4px;
			color: #FC9D03;
			vertical-align: bottom;
			@include font-size(11);
			text-align: center;
			padding: rem(2) rem(5);
			margin: rem(0) rem(5) rem(3) rem(0);
			min-width: 70px;
      @include mq(md) {
        vertical-align: 1px;
        margin: rem(0) rem(5) rem(0) rem(0);
      }
      @include mq(xl) {
      vertical-align: 1px;
        margin: rem(0) rem(5) rem(3) rem(0);
      }
		}
	}

	&_heading {
		@include font-size(20);
		line-height: 1.3;
		font-weight: bold;
		margin-bottom: rem(12);
		@include mq {
			padding-top: rem(17);
		}
	}

	&_box {
		border-radius: 8px;
		background: #FFEACC;
		padding: rem(29) rem(31) rem(38);
		@include font-size(14);
		line-height: 1.72;
		margin-bottom: rem(40);
		border: 1px solid #A6ADB4;
		@include mq {
			border: none;
			margin: 0 rem(-15) rem(16);
			padding: rem(24) rem(16);
			@include font-size(12);
			line-height: 1.667;
		}
	}
	&_list {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		margin: 0 rem(-4);
		margin-bottom: 2rem;
		.item {
			padding: rem(4);
			width: 25%;
			@include mq(lg) {
				width: 50%;
			}
			@include mq(sm) {
				width: 100%;
				border-bottom: 1px solid #EBEDED;
				margin-bottom: rem(11);
				&:last-child {
					margin-bottom: 0;
				}
			}
			a {
				color: inherit;
			}
			.wrap {
				overflow: hidden;
				border-radius: 8px;
				box-shadow: 0 3px 6px rgba(0,0,0,0.16);
				@include mq(sm) {
					display: flex;
          border-radius: initial;
					box-shadow: none;
				}
				.img {
					@include mq(sm) {
						width: 28.9%;
					}
				}
				.content {
					padding: rem(17) rem(16) rem(18);
					@include mq(sm) {
						width: 71.1%;
						padding: 0px rem(10) rem(9) rem(8);
					}
				}
				.name {
					@include font-size(11);
					line-height: 1.3;
					margin-bottom: rem(5);
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					@include mq {
						margin-bottom: rem(3);
					}
				}
				.des {
          @include font-size(14);
          line-height: 1.5;
          font-weight: bold;
          line-clamp: 2;
          min-height: 45px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
				}
			}
		}
	}
}

.relate_box {
	margin-top: rem(77);
	@include mq {
		margin-top: rem(42);
	}
}

.recruit_field-wrapper {
  .wrapper_btn-more {
    position: relative;
    margin-bottom: rem(25);
    .box_btn-more {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      .btn {
        max-width: 295px;
        background: #A6ADB4;
        color: #fff;
      }
    }
  }

  &.hidden {
    .recruit_field {
      &.toggle-start {
        dd {
          height: 100px;
          overflow: hidden;
        }
      }
      &.toggle-visible {
        display: none;
      }
    }
    .box_btn-more {
      position: absolute;
      height: 100px;
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, #fff 100%);
      background: linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, #fff 100%);
      top: -100px;
    }
  }
}

.link-page {
  display: none;
  @include mq(){
    display: block;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    padding: 1rem 0;
    background-color: rgba(0,0,0,0.2);
    .item{
      width: 80%;
      display: block;
      margin-inline: auto;
    }
  }
}

/*タブ切り替え全体のスタイル*/
.tab {
  padding-bottom: 40px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 48px auto 48px;
  text-align: justify;
  display: flex;
  flex-wrap: wrap;
  &_item {
    background-color: #d9d9d9;
    line-height: 50px;
    font-size: 16px;
    text-align: center;
    color: #565656;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    transition: all 0.2s ease;
    position: relative;
    z-index: 1;
    cursor: pointer;
    flex: 1;
    @include mq (sm) {
      font-size: 11px;
    }
    &:hover {
      opacity: 0.75;
      cursor: pointer;
    }
  }
  &_content {
    display: none;
    flex: 100%;
    padding: 24px 24px 0;
    clear: both;
    overflow: hidden;
    &_heading {
      color: #333;
      text-decoration: 3px underline #FC9C04;
      text-underline-offset: 8px;
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      padding-bottom: 24px;
      @include mq (sm) {
        font-size: 16px;
      }
    }
  }
  input:checked + .tab_item {
    background-color: #fff;
    color: rgb(252, 156, 4);
    border-bottom: 4px solid rgb(252, 156, 4);
  }
  input[name="tab_item"] {
    display: none;
  }
  /*選択されているタブのコンテンツのみを表示*/
  #summary:checked ~ #summary_content,
  #employment_type_part-time:checked ~ #employment_type_part-time_content,
  #employment_type_regular:checked ~ #employment_type_regular_content,
  #employment_type_contract:checked ~ #employment_type_contract_content,
  #employment_type_subcontract:checked ~ #employment_type_subcontract_content {
    display: block;
  }
}
