.about-sec-mv {
	margin-bottom: rem(80);
	position: relative;

	.pc {
		width: 100%;
		height: rem(600);
	}

	.sp {
		width: 100%;
		height: rem(350);
	}

	img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}

	@include mq(ipd) {
		margin-bottom: rem(30);

		.pc {
			width: 100%;
			height: rem(450);
		}
	}
}
.about-mv-content {
	position: absolute;
	width: 1160px;
	bottom: 6.8vw;
	left: 50%;
	transform: translateX(-50%);
	@include mq(xl) {
		width: auto;
		left: rem(50);
		bottom: rem(50);
		transform: translateX(0);
	}
	@include mq() {
		width: 100%;
		padding: 0 rem(15);
		bottom: 6.8vw;
		left: 50%;
		transform: translateX(-50%);
	}
	.about-content {
		width: rem(600);
		height: rem(238);
		background: rgba(#fff, 0.8);
		padding: rem(35) rem(42) rem(40);
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		@include mq(lg) {
			padding: rem(30);
			height: auto;
		}
		@include mq() {
			width: 92.75%;
			height: rem(137);
			padding: rem(19) rem(16);
		}
		.about-ttl-mv {
			@include font-size(50);
			font-weight: 900;
			font-family: "Kosugi";
			color: #202124;
			line-height: 1.2;
			@include mq() {
				@include font-size(28);
			}
			@include mq(xs) {
				@include font-size(24);
			}
		}
	}
}
.top-txt-orange {
	font-family: $font-notoJp;
	@include font-size(16);
	color: #FC9C04;
	display: block;
	margin-bottom: rem(8);
	@include mq(xs) {
		@include font-size(14);
		margin-bottom: 5px;
	}
}
.about-ttl-top {
	@include font-size(30);
	font-weight: bold;
	line-height: 1.6;
	color: #202124;
	margin-bottom: rem(78);
	@include mq(lg) {
		margin-bottom: rem(50);
		@include font-size(28);
	}
	@include mq() {
		@include font-size(18);
		line-height: 1.6;
		margin-bottom: rem(40);
		text-align: left;
	}
	&-style02{
		letter-spacing: 2px;
		@include mq() {
			letter-spacing: 0;
			margin-bottom: rem(32);
		}
	}
}

.about-list-item-page {
	margin-bottom: rem(160);
	@include mq(lg) {
		margin-bottom: rem(100);
	}
	@include mq() {
		margin-bottom: rem(40);
	}
	.item {
		display: flex;
		align-items: flex-start;
		margin-bottom: rem(125);
		@include mq(lg) {
			margin-bottom: rem(80);
		}
		@include mq() {
			flex-wrap: wrap;
			margin-bottom: rem(40);
		}
		&:last-of-type {
			margin-bottom: 0;
		}
		&_img {
			width: 56.89%;
			position: relative;
			@include mq(lg) {
				width: 50%;
			}
			img {
				position: relative;
				z-index: 2;
			}
			iframe {
				position: relative;
				z-index: 2;
				width: 100%;
				height: 400px;
				@include mq(xs) {
					height: 200px;
				}
			}
			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				bottom: rem(-40);
				left: rem(-40);
				background: rgba(252, 156, 4, 0.1);
				position: absolute;
				@include mq(xl) {
					bottom: rem(-20);
					left: rem(-20);
				}
			}
		}
		&_content {
			width: calc(100% - 56.89%);
			padding-left: rem(70);

			@include mq(xl) {
				width: 100%;
				padding-left: rem(30);
			}

			@include mq(lg) {
				width: 50%;
			}
			@include mq() {
				width: 100%;
				padding: 0;
		}
		&_sub {
			max-width: 220px;
			margin: auto;
			@include mq(xl) {
				max-width: 220px;
				margin: auto;
			}

			@include mq(lg) {
				max-width: 220px;
				margin: auto;
			}
		}
			.txt-orange {
				margin-bottom: rem(36);
				position: relative;
				display: inline-block;
				@include mq(lg) {
					margin-bottom: rem(25);
				}
				@include mq() {
					display: block;
					margin-bottom: rem(15);
				}
				&:after {
					content: '';
					width: rem(240);
					height: 1px;
					background: #FC9C04;
					left: calc(100% + 20px);
					top: 54%;
					position: absolute;
					@include mq(lg) {
						width: rem(200);
					}
					@include mq() {
						width: 62.89%;
						left: auto;
						right: 0;
					}
				}
			}
			.about_ttlCom01 {
        @include font-size(40);
          font-family: $font-notoJp;
          line-height: 1.2;
          font-weight: bold;
          color: #202124;
          margin-bottom: rem(28);
        @include mq(lg) {
          margin-bottom: rem(20);
          font-size: 20px;
        }
        @include mq() {
          margin-bottom: rem(12);
          font-size: 20px;
        }
			}
			.about_txt-des {
				@include font-size(16);
				font-family: $font-notoJp;
				line-height: 1.65;
				color: #202124;
				margin-bottom: rem(25);

				&__heading {
					font-weight: bold;
				}

				&__menu {
					margin-left: 16px;
					margin-bottom: 13px;
				}

				.pc {
					@include mq(xl) {
						@include mq(md-min) {
							display: none;
						}
					}
				}
			}

			.about_btn-group {
				max-width: 220px;
				margin: 0 auto;
        .btn.btn-orange {
          @include font-size(15);
        }
				@include mq() {

				}
			}
			.img {
				position: relative;
				width: calc(100% - 20px);
				margin-right: 0;
				margin-left: auto;
				margin-bottom: rem(35);
				img {
					position: relative;
					z-index: 2;
					width: 100%;
				}
				iframe {
					position: relative;
					z-index: 2;
					width: 100%;
					height: 400px;
					@include mq(sm) {
						height: 250px;
					}
				}
				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					bottom: rem(-20);
					left: rem(-20);
					background: rgba(252, 156, 4, 0.1);
					position: absolute;
				}
			}
		}
		&:nth-of-type(even) {
			margin-bottom: rem(160);
			@include mq(lg){
				margin-bottom: rem(80);
			}
			@include mq() {
				flex-wrap: wrap;
				margin-bottom: rem(40);
				@include mq{
					margin-bottom: rem(15);
				}
			}
			.item_img {
				order: 2;
				&:after {
					left: auto;
					right: rem(-40);
				}
			}
			.item_content {
				order: 1;
				padding-left: 0;
				padding-right: rem(70);
				@include mq(xl) {
					padding-right: rem(30);
				}
				@include mq() {
					padding: 0;
				}
				.img {
					margin-right: auto;
					margin-left: 0;
					margin-bottom: rem(40);
					&:after {
						left: auto;
						right: rem(-20);
					}
				}

			}
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	&-style02 {
		@include mq() {
			margin-bottom: rem(35);
		}
		.item {
			margin-bottom: rem(160);
			@include mq(lg) {
				margin-bottom: rem(100);
			}
			@include mq() {
				margin-bottom: rem(15);
			}
			.about_ttlCom01 {
				margin-bottom: rem(48);
				@include mq() {
					margin-bottom: rem(20);
				}
			}
			&:nth-of-type(even) {
				margin-bottom: rem(120);
				@include mq() {
					margin-bottom: rem(15);
				}
			}
		}
	}
}

